'use client';

// import Menu from "@/components/navbar/Menu"
import { useEffect, useState } from 'react';
// import ShowCategoriesMenu from "./ShowCategoriesMenu";
import style from './Category.module.css';
import CategoriesMenu from './ShowCategoriesMenu';
import PharmaProfile from '../PharmaProfile/page';
import { usePathname } from 'next/navigation';

const CategoriesMenuContainer = () => {
	const pathname = usePathname();
	const [navbarItems, setNavbarItems] = useState([]);

	const pathSegments = pathname.split('/');

	const categoryTitle = pathSegments[pathSegments.length - 1];

	useEffect(() => {
		const apiUrl = `${process.env.NEXT_PUBLIC_CMS_URL}${process.env.NEXT_PUBLIC_PHARMA_NAVBAR_MENU_API}?name=${process.env.NEXT_PUBLIC_PHARMA_NAVBAR_CATEGORY_MENU_API}&country=${process.env.NEXT_PUBLIC_COUNTRY_CODE}`;

		fetch(apiUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((result) => {
				// Extracting the locations array from the response object
				const navArray = result && result.data ? result.data : [];
				if (Array.isArray(navArray)) {
					setNavbarItems(navArray[0]?.items?.menuData);
				}
			})
			.catch((error) => {
				console.error('Fetch error:', error);
			});
	}, []);

	return (
		<div
			className='border-bottom mb-3'
			style={
				categoryTitle
					? categoryTitle === 'about'
						? { padding: '1.3rem 1.3rem 1.3rem 1.3rem' }
						: categoryTitle === 'orders'
						? { padding: '1.3rem 1.3rem 1.3rem 1.3rem' }
						: categoryTitle === 'shop'
						? { padding: '1.3rem 1.3rem 1.3rem 1.3rem' }
						: pathSegments.includes('details')
						? { padding: '1.3rem 1.3rem 1.3rem 1.3rem' }
						: categoryTitle === 'profile'
						? { padding: '1.3rem 1.3rem 1.3rem 1.3rem' }
						: categoryTitle === 'checkout'
						? { padding: '1.3rem 1.3rem 1.3rem 1.3rem' }
						: pathSegments.includes('articles')
						? { padding: '1.3rem 1.3rem 1.3rem 1.3rem' }
						: categoryTitle.includes('best-selling-items')
						? { padding: '1.3rem 1.3rem 1.3rem 1.3rem' }
						: pathname.includes('ncd')
						? { padding: '1.3rem 1.3rem 1.3rem 1.3rem' }
						: null
					: // { margin: '-1.5rem' }
					  null
			}>
			<div className='container'>
				{pathname === '/' && (
					<div className={style.profile}>
						<PharmaProfile />
					</div>
				)}

				<CategoriesMenu
					navbarItems={navbarItems}
					categoryTitle={categoryTitle}
				/>
			</div>
		</div>
	);
};

export default CategoriesMenuContainer;
