import React, { useState } from "react";
import styled from "@emotion/styled";
import Typography from "@/components/UI/Typography";
import Button from "../Buttons/Button";
import InputForm from "../InputForm/InputForm";
import Container from "../Container";

const fb = "feedback";

const Feedback = styled("div")`
  padding: ${(props) => props.theme.spaces.medium1} 0
    ${(props) => props.theme.spaces.small};
  background: ${(props) => props.theme.colors.white};
  position: absolute;
  display: none;
  right: 0;
  left: 0;
  z-index: 10;

  @media (max-width: 768px) {
    // position: fixed;
    bottom: 0;
    display: block;
    .feedback-message {
      top: 10rem !important;
      z-index: 11 !important;
    }
  }

  &.active {
    display: block;
  }

  .feedback {
    &__close {
      appearance: none;
      border: none;
      outline: none;
      background: transparent;
      color: ${(props) => props.theme.colors.magenta};
      font-weight: 700;
      position: absolute;
      font-size: 30px;
      top: 20px;
      right: 20px;
      opacity: 1;
    }

    &__title {
      text-align: center;
      margin: 0 0 ${(props) => props.theme.spaces.small};
    }

    &__sub-title {
      text-align: center;
      font-size: 12px;
      font-weight: normal;
      margin: 0 0 ${(props) => props.theme.spaces.small};
    }

    &__textarea {
      height: 150px !important;
      resize: none !important;
      rows: 10 !important;
      max-lenght: 200 !important;
    }

    &__submit {
      ${(props) => props.theme.buttons.primary};
      display: flex;
      margin: 0 auto;
      width: 100%;
      max-width: 350px;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .form-input {
    margin: 0 0 ${(props) => props.theme.spaces.small};

    &__label {
      font-size: 12px;
      font-weight: 700;
      margin: 0 0 ${(props) => props.theme.spaces.mini};
    }
  }
`;

const inputs = [
  {
    label: "Your Name",
    name: "feedback_name",
    className: `${fb}__form-control`,
    type: "text",
  },
  {
    label: "Your Email",
    name: "feedback_email",
    className: `${fb}__form-control`,
    type: "email",
  },
  {
    label: "Feedback",
    name: "feedback_message",
    className: `${fb}__textarea`,
    type: "textarea",
  },
  {
    btnSize: "big",
    name: "submit",
    className: `${fb}__submit`,
    type: "submit",
    title: "Submit Feedback",
  },
];

const FeedbackForm = ({ closeStateHandler }) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(0);
  const [isAlertVisible, setIsAlertVisible] = useState(true);

  const onSubmitForm = async (event) => {
    event.preventDefault();

    setIsAlertVisible(true);

    const formData = new FormData(event.target);
    formData.append("country_code", process.env.NEXT_PUBLIC_COUNTRY_CODE);
    const option = {
      method: "POST",
      body: formData,
    };

    const result = await fetch(
      process.env.NEXT_PUBLIC_FEEDBACK_NOTIFICATION_API_PATH,
      option
    );
    const requestResult = await result.json();
    setIsFormSubmitted(requestResult.success === "ok" ? 1 : 2);

    setTimeout(() => {
      setIsAlertVisible(false);
      closeStateHandler && closeStateHandler();
    }, 5000);
  };

  return (
    <>
      {!isFormSubmitted && (
        <Feedback className="feedback-form active">
          <Container>
            <Typography variant={"h4"} type={"h4"} className={`${fb}__title`}>
              Feedback
            </Typography>
            <Typography
              variant={"h6"}
              type={"p"}
              className={`${fb}__sub-title`}
            >
              Your comments and suggestions help us improve our website and
              service for you.
            </Typography>
            <Typography variant={"p"} type={"p"} className={`${fb}__sub-title`}>
              Please let us know what products you would like to see on our
              website, so we can source them for you.
            </Typography>
            <form
              id="form_feedback"
              className={`${fb}__form`}
              onSubmit={onSubmitForm}
            >
              {inputs.map((item, index) => {
                return <InputForm data={item} key={index} />;
              })}
            </form>
          </Container>

          <Button
            title={"×"}
            type="button"
            className={`${fb}__close`}
            onClick={() => closeStateHandler()}
          />
        </Feedback>
      )}
      {isFormSubmitted === 1 && isAlertVisible && (
        <div
          className="alert alert-dismissible fade show alert-success feedback-message"
          role="alert"
        >
          <Typography variant={"p"} type={"span"}>
            <center>
              {" "}
              Thank You. We have received your feedback successfully.
            </center>
          </Typography>
        </div>
      )}
        {isFormSubmitted === 2 && isAlertVisible && (
        <div
          className="alert alert-dismissible fade show alert-warning feedback-message"
          role="alert"
        >
          <Typography variant={"p"} type={"span"}>
            Sorry! Your request wasn&apos;t processed correctly. Please try a
            bit later.
          </Typography>
        </div>
      )}
    </>
  );
};

export default FeedbackForm;
