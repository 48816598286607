const { default: useCart } = require('@/utils/useCart');
const { default: Image } = require('next/image');
import { useState, useEffect } from 'react';
import style from '../../app/details/details.module.css';

const CartInputField = ({ cartItems, productDetails, selectedUnit }) => {
	const [quantity, setQuantity] = useState(1);
	const [lastValidQuantity, setLastValidQuantity] = useState(1);

	const {
		addToCart,
		reduceProductQuantity,
		increaseProductQuantity,
		manualProductQuantity,
		removeProduct,
	} = useCart();

	useEffect(() => {
		const cartProduct = cartItems?.products.find(
			(prod) => prod.id === productDetails.id
		);
		if (cartProduct) {
			setQuantity(cartProduct.quantity); // Set quantity from cart
			setLastValidQuantity(cartProduct.quantity);
		}
	}, [cartItems, productDetails]);

	const handleUOMaddToCart = (product) => {
		addToCart(product, selectedUnit);
	};

	const handleBlurQuantity = () => {
		if (quantity === '') {
			setQuantity(lastValidQuantity); // Revert to the last valid quantity if the input is empty
		}
	};

	const handleChangeQuantity = (event) => {
		const newlyQuantity = event.target.value;
		if (!isNaN(newlyQuantity) && newlyQuantity.length <= 4) {
			const newQuantity = parseInt(event.target.value, 10);
			if (
				!isNaN(newQuantity) &&
				newQuantity >= 1
				// &&
				// newQuantity <= productDetails.total_inventory
			) {
				setQuantity(newQuantity);
				setLastValidQuantity(newQuantity);
				manualProductQuantity(productDetails, newQuantity);
			} else if (!isNaN(newQuantity) && newQuantity === 0) {
				removeProduct(productDetails);
			} else if (event.target.value === '') {
				setQuantity(''); // Allow clearing the input field
			}
		}
	};

	const increaseproductDetailsQuantity = (productDetails) => {
		if (quantity < 9999) {
			increaseProductQuantity(productDetails);
		}
	};

	return (
		<div
			className={style.productButtons}
			style={{ display: 'flex', paddingTop: '0.5rem', paddingBottom: '1rem' }}>
			{!cartItems?.products.find((item) => item.id === productDetails.id)
				?.quantity ? (
				<button
					style={{
						backgroundColor: '#E2156A',
						width: 'auto',
						height: 'auto',
						fontWeight: 'normal',
						marginRight: '0.5rem',
						marginTop: 0,
						marginBottom: 0,
					}}
					onClick={() => handleUOMaddToCart(productDetails)}>
					Add to Cart
				</button>
			) : (
				<div
					className={style.productAddToCart}
					style={{
						backgroundColor: 'transparent',
						right: '0rem',
					}}>
					<Image
						src='/images/minus-sign-circle.svg'
						width={18}
						height={18}
						style={{ cursor: 'pointer' }}
						alt='reduce'
						onClick={() => reduceProductQuantity(productDetails)}
						// onClick={() => reduceproductDetailsQuantity(productDetails)}
					/>

					<span
						style={{
							fontSize: '12px',
							margin: '0 2px',
							display: 'inline-block',
						}}>
						<input
							type='number'
							className={`text-center ${style.quantityInput}`}
							value={quantity}
							onChange={handleChangeQuantity}
							onBlur={handleBlurQuantity}
							min='0'
							// max={
							// 	productDetails.total_inventory > 9999
							// 		? '4'
							// 		: productDetails.total_inventory
							// }
							max='4'
							style={{
								padding: '0px',
								borderColor: '#e2156a',
								width: '40px',
								borderWidth: '1px',
								borderRadius: '10px',
							}}
						/>
					</span>

					<Image
						src='/images/plus-sign-circle.svg'
						width={18}
						height={18}
						style={
							quantity < 9999
								? { cursor: 'pointer' }
								: { opacity: '0.5', cursor: 'not-allowed' }
						}
						alt='increase'
						// onClick={() => increaseProductQuantity(productDetails)}
						onClick={() => increaseproductDetailsQuantity(productDetails)}
					/>
				</div>
			)}
		</div>
	);
};

export default CartInputField;
