import React, { useState } from 'react';
import style from './ProductList.module.scss';

function UomCheckBox({ options, selection, setSelection }) {
	const handleChange = (uom) => {
		setSelection(uom);
	};

	function Camelize(str) {
		return str
			.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
				return index === 0 ? word.toUpperCase() : word.toLowerCase();
			})
			.replace(/\s+/g, '');
	}

	return (
		<div className='w-100 d-flex flex-column fw-bold justify-content-between'>
			{options?.length > 0 &&
				options?.map((uom, index) => {
					return (
						<div key={index}>
							<label
								key={index}
								style={{ cursor: 'pointer', fontSize: '12.8px' }}>
								<div className='d-flex'>
									<p className='m-0 p-0'>
										<input
											type='radio'
											className={style.radioInput}
											name={`quantity-${uom?.product_id}`}
											value={uom?.unit_of_measure}
											checked={selection === uom?.unit_of_measure}
											onChange={() => {
												handleChange(uom?.unit_of_measure);
											}}
										/>
										&nbsp;&nbsp;
										{Camelize(uom?.unit_of_measure)}
										{' at '}{' '}
										{process.env.NEXT_PUBLIC_COUNTRY_CODE === 'KE'
											? `KES ${uom?.price.toLocaleString()}`
											: `RWF ${uom?.price.toLocaleString()}`}
									</p>
								</div>
							</label>
						</div>
					);
				})}
		</div>
	);
}

export default UomCheckBox;
