import { useAppSelector } from '@/lib/hooks';
import style from './ProductList.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState, useCallback } from 'react';
import useCart from '@/utils/useCart';
import UomCheckBox from './UomCheckBox';
import { useRouter } from 'next/navigation';
import debounce from 'lodash.debounce';
import CartInputField from './CartInputField';

const ProductBox = ({ product, carouselIndices }) => {
	const router = useRouter();
	const {
		addToCart,
		reduceProductQuantity,
		increaseProductQuantity,
		manualProductQuantity,
		removeProduct,
	} = useCart();

	const cartItems = useAppSelector((state) => state.cartItems.cartItems);

	const [selctedUnit, setSelectedUnit] = useState('');
	const [quantity, setQuantity] = useState(1);
	const [lastValidQuantity, setLastValidQuantity] = useState(1);

	// useEffect(() => {
	// 	setSelectedUnit(product.pharma_product_uoms[0]?.unit_of_measure);
	// 	if (cartItems?.products.length > 0) {
	// 		const isSelected = cartItems?.products.find(
	// 			(prod) => prod.id === product.id
	// 		);
	// 		console.log('Testing--->', isSelected);
	// 		isSelected &&
	// 			setSelectedUnit(isSelected.uom) &&
	// 			setQuantity(isSelected.quantity);
	// 	}
	// }, [cartItems, carouselIndices]);

	useEffect(() => {
		setSelectedUnit(product.pharma_product_uoms[0]?.unit_of_measure);
		const cartProduct = cartItems?.products.find(
			(prod) => prod.id === product.id
		);
		if (cartProduct) {
			setSelectedUnit(cartProduct.uom);
			setQuantity(cartProduct.quantity); // Set quantity from cart
			setLastValidQuantity(cartProduct.quantity);
		}
	}, [cartItems, carouselIndices]);

	function handleSetSelection(uom) {
		// update uom on cart and the price
		cartItems?.products.length > 0 &&
			cartItems?.products.find(
				(prod) => prod.id === product.id && addToCart(product, uom)
			);
		setSelectedUnit(uom);
	}

	const [screenWidth, setScreenWidth] = useState(
		typeof window !== 'undefined' ? window.innerWidth : null
	);

	function stripHtmlTags(htmlString, maxLength) {
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = htmlString;
		let textContent = tempDiv.textContent || tempDiv.innerText || '';
		// Truncate to 200 characters
		if (textContent.length > maxLength) {
			textContent = textContent.substring(0, maxLength) + '...';
		}
		return textContent;
	}

	const handleUOMaddToCart = (product) => {
		addToCart(product, selctedUnit);
	};

	const maxLength = 200;

	const handleClickProductDetails = () => {
		window.location.href = `/details?id=${product.id}&country=${process.env.NEXT_PUBLIC_COUNTRY_CODE}`;
	};

	// Debounced API call
	const debouncedManualProductQuantity = useCallback(
		debounce((product, newQuantity) => {
			manualProductQuantity(product, newQuantity);
		}, 1000),
		[]
	);

	const handleChangeQuantity = (event) => {
		const newQuantity = parseInt(event.target.value, 10);
		if (
			!isNaN(newQuantity) &&
			newQuantity >= 1 &&
			newQuantity <= product.total_inventory
		) {
			setQuantity(newQuantity);
			setLastValidQuantity(newQuantity);
			// manualProductQuantity(product, newQuantity);
			debouncedManualProductQuantity(product, newQuantity);
		} else if (!isNaN(newQuantity) && newQuantity === 0) {
			removeProduct(product);
		} else if (event.target.value === '') {
			setQuantity(''); // Allow clearing the input field
		}
	};

	const handleBlurQuantity = () => {
		if (quantity === '') {
			setQuantity(lastValidQuantity); // Revert to the last valid quantity if the input is empty
		} else {
			// Ensure the quantity is valid on blur and make the API call immediately if needed
			const newQuantity = parseInt(quantity, 10);
			if (
				!isNaN(newQuantity) &&
				newQuantity >= 1 &&
				newQuantity <= product.total_inventory
			) {
				debouncedManualProductQuantity.flush(); // Ensure the debounced function is executed immediately
			}
		}
	};

	useEffect(() => {
		// Clean up the debounce on unmount
		return () => {
			debouncedManualProductQuantity.cancel();
		};
	}, [debouncedManualProductQuantity]);

	return (
		<section className={style.gridSection} style={{ marginBottom: '1rem' }}>
			<div>
				<div className={style.product}>
					<Link
						// href={`/details?id=${product.id}&country=${process.env.NEXT_PUBLIC_COUNTRY_CODE}`}
						href='#'
						style={{ textDecoration: 'none' }}
						onClick={() => handleClickProductDetails()}>
						<Image
							src={
								// product.image_urls?.length
								product?.prescription_req === 0
									? product?.image_urls === null ||
									  product.image_urls?.length === 0
										? '/images/no-image.png'
										: product.image_urls[0] === null
										? '/images/no-image.png'
										: product.image_urls[0]
									: '/images/no-image.png'
							}
							alt='Product'
							className={style.productImage}
							width={195}
							height={110}
							title={
								product?.description
									? stripHtmlTags(product?.description, maxLength)
									: product?.name
							}
						/>
					</Link>
					{!cartItems?.products.find((item) => item.id === product.id)
						?.quantity ? (
						<div
							className={style.productAddToCart}
							onClick={
								// () => addToCart(product)
								() => handleUOMaddToCart(product)
							}
							onKeyDown={() => handleUOMaddToCart(product)}>
							<Image
								src='/images/add-to-cart.svg'
								alt='Add to Cart'
								width={16}
								height={16}
							/>
						</div>
					) : (
						<div
							className={style.productAddToCart}
							style={{
								backgroundColor: 'transparent',
								bottom: '0rem',
								right: '0rem',
								marginBottom: 0,
							}}>
							<CartInputField
								cartItems={cartItems}
								productDetails={product}
								selectedUnit={selctedUnit}
							/>
						</div>
					)}
				</div>
				<div className={style.productContainer}>
					<div className={style.productName}>
						{screenWidth > 527 && product.name.length > 18 ? (
							<div className={style.marqueeContainer}>
								<div className={style.marqueeWord}>{product.name}</div>
							</div>
						) : (
							product.name
						)}
					</div>
					<div
						className={style.productFooterContainer}
						style={{
							alignItems: 'flex-start',
							flexDirection: 'row',
							justifyContent: 'space-between',
							paddingTop: '0.8rem',
						}}>
						<UomCheckBox
							options={product?.pharma_product_uoms}
							selection={selctedUnit}
							setSelection={(uom) => handleSetSelection(uom)}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ProductBox;
