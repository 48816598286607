import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import Typography from "@/components/UI/Typography";
import Link from "next/link";
import FeedbackForm from "./FeedbackForm";
import Button from "../Buttons/Button";
import Image from "next/image";
import Container from "../Container";

const fm = "footer-main";

const Wrapper = styled("div")`
  .insta-colors {
    filter: invert(30%) sepia(80%) saturate(1488%) hue-rotate(254deg)
      brightness(78%) contrast(93%);
  }
  .fb-colors {
    filter: invert(29%) sepia(7%) saturate(7133%) hue-rotate(188deg)
      brightness(99%) contrast(77%);
  }
  .twitter-colors {
    filter: invert(58%) sepia(51%) saturate(4531%) hue-rotate(176deg)
      brightness(100%) contrast(91%);
  }
  .footer-main {
    &__col {
      display: flex;
      border-top: 1px solid ${(props) => props.theme.colors.grey2};
      padding: ${(props) => props.theme.spaces.medium1} 0;
      margin: 0 0 ${(props) => props.theme.spaces.normal};

      @media screen and (max-width: 415px) {
        flex-direction: column;
        align-items: center;
      }

      &.feedback {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      &.pay-by-phone {
        @media screen and (max-width: 768px) {
          margin-bottom: 0;
        }
      }

      p {
        font-size: 12px;
        margin: 0;
      }

      a {
        color: ${(props) => props.theme.colors.main};
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      h5 {
        margin: 0 0 ${(props) => props.theme.spaces.small};
      }

      &_social a {
        padding: 0 ${(props) => props.theme.spaces.little};
        color: ${(props) => props.theme.colors.black};
      }

      div {
        @media screen and (max-width: 415px) {
          width: 100%;
          margin: ${(props) => props.theme.spaces.small} 0;
        }
      }
    }

    &__contact-us {
      &_links {
        position: relative;
        padding-left: ${(props) => props.theme.spaces.normal};

        &:after {
          position: absolute;
          content: "";
          display: block;
          left: 0;
          top: 3px;
          z-index: 1;
          width: 12px;
          height: 12px;
        }
      }

      &_address {
        &:after {
          background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfMTJfMTEpIj48cGF0aCBkPSJNNS41Mzc1MyAxMS43NTc5QzIuMTMyMTEgNi44MjEwNCAxLjUgNi4zMTQzNyAxLjUgNC41QzEuNSAyLjAxNDcxIDMuNTE0NzEgMCA2IDBDOC40ODUyOSAwIDEwLjUgMi4wMTQ3MSAxMC41IDQuNUMxMC41IDYuMzE0MzcgOS44Njc4OSA2LjgyMTA0IDYuNDYyNDcgMTEuNzU3OUM2LjIzODk5IDEyLjA4MDcgNS43NjA5OCAxMi4wODA3IDUuNTM3NTMgMTEuNzU3OVpNNiA2LjM3NUM3LjAzNTU0IDYuMzc1IDcuODc1IDUuNTM1NTQgNy44NzUgNC41QzcuODc1IDMuNDY0NDYgNy4wMzU1NCAyLjYyNSA2IDIuNjI1QzQuOTY0NDYgMi42MjUgNC4xMjUgMy40NjQ0NiA0LjEyNSA0LjVDNC4xMjUgNS41MzU1NCA0Ljk2NDQ2IDYuMzc1IDYgNi4zNzVaIiBmaWxsPSIjNEQ0RDREIi8+PC9nPjxkZWZzPjxjbGlwUGF0aCBpZD0iY2xpcDBfMTJfMTEiPjxyZWN0IHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgZmlsbD0id2hpdGUiLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4=)
            center center no-repeat;
        }
      }

      &_email {
        &:after {
          background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNC4xMjUgNS4wNjI0NUg3Ljg3NUM4LjA4MjE5IDUuMDYyNDUgOC4yNSA0Ljg5NDY0IDguMjUgNC42ODc0NVY0LjMxMjQ1QzguMjUgNC4xMDUyNiA4LjA4MjE5IDMuOTM3NDUgNy44NzUgMy45Mzc0NUg0LjEyNUMzLjkxNzgxIDMuOTM3NDUgMy43NSA0LjEwNTI2IDMuNzUgNC4zMTI0NVY0LjY4NzQ1QzMuNzUgNC44OTQ2NCAzLjkxNzgxIDUuMDYyNDUgNC4xMjUgNS4wNjI0NVpNMy43NSA2LjkzNzQ1QzMuNzUgNy4xNDQ2NCAzLjkxNzgxIDcuMzEyNDUgNC4xMjUgNy4zMTI0NUg3Ljg3NUM4LjA4MjE5IDcuMzEyNDUgOC4yNSA3LjE0NDY0IDguMjUgNi45Mzc0NVY2LjU2MjQ1QzguMjUgNi4zNTUyNiA4LjA4MjE5IDYuMTg3NDUgNy44NzUgNi4xODc0NUg0LjEyNUMzLjkxNzgxIDYuMTg3NDUgMy43NSA2LjM1NTI2IDMuNzUgNi41NjI0NVY2LjkzNzQ1Wk02IDkuNzc2NDNDNS42MTUxNiA5Ljc3NjQzIDUuMjMwMzEgOS42NTc4NCA0LjkwMTcyIDkuNDIwNDJMMCA1Ljg3OTQ4VjEwLjg3NUMwIDExLjQ5NjMgMC41MDM2NzIgMTIgMS4xMjUgMTJIMTAuODc1QzExLjQ5NjMgMTIgMTIgMTEuNDk2MyAxMiAxMC44NzVWNS44Nzk0OEw3LjA5ODI4IDkuNDIwNDJDNi43Njk2OSA5LjY1NzYxIDYuMzg0ODQgOS43NzY0MyA2IDkuNzc2NDNaTTExLjU2OSAzLjgxOTA5QzExLjM2MTYgMy42NTY0MyAxMS4xNjQ5IDMuNTAzMzkgMTAuODc1IDMuMjg0NDhWMi4yNDk5NUMxMC44NzUgMS42Mjg2MiAxMC4zNzEzIDEuMTI0OTUgOS43NSAxLjEyNDk1SDcuOTMyNDJDNy44NjExNyAxLjA3MzM5IDcuNzk0ODQgMS4wMjUxMSA3LjcyMDU1IDAuOTcxMkM3LjMyNjU2IDAuNjgzNjIyIDYuNTQzNzUgLTAuMDA4MjUyODggNiAtNC45NzU2OWUtMDVDNS40NTYyNSAtMC4wMDgyNTI4OCA0LjY3MzY3IDAuNjgzNjIyIDQuMjc5NDUgMC45NzEyQzQuMjA1MTYgMS4wMjUxMSA0LjEzODgzIDEuMDczMzkgNC4wNjc1OCAxLjEyNDk1SDIuMjVDMS42Mjg2NyAxLjEyNDk1IDEuMTI1IDEuNjI4NjIgMS4xMjUgMi4yNDk5NVYzLjI4NDQ4QzAuODM1MDc4IDMuNTAzMTUgMC42Mzg0MzggMy42NTY0MyAwLjQzMTAxNiAzLjgxOTA5QzAuMjk2NzU2IDMuOTI0MzEgMC4xODgxODMgNC4wNTg2OCAwLjExMzUxMSA0LjIxMjA1QzAuMDM4ODM4OSA0LjM2NTQxIDIuMzEwNDhlLTA1IDQuNTMzNzUgMCA0LjcwNDMzTDAgNC45NTM5M0wyLjI1IDYuNTc5MzNWMi4yNDk5NUg5Ljc1VjYuNTc5MzNMMTIgNC45NTM5M1Y0LjcwNDMzQzEyIDQuMzU4ODYgMTEuODQxMSA0LjAzMjM3IDExLjU2OSAzLjgxOTA5WiIgZmlsbD0iYmxhY2siLz48L3N2Zz4=)
            center center no-repeat;
        }
      }

      &_phone {
        &:after {
          background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAuMTI1IDAuNzVIMS44NzVDMS41NzY2MyAwLjc1IDEuMjkwNDggMC44Njg1MjYgMS4wNzk1IDEuMDc5NUMwLjg2ODUyNiAxLjI5MDQ4IDAuNzUgMS41NzY2MyAwLjc1IDEuODc1TDAuNzUgMTAuMTI1QzAuNzUgMTAuNDIzNCAwLjg2ODUyNiAxMC43MDk1IDEuMDc5NSAxMC45MjA1QzEuMjkwNDggMTEuMTMxNSAxLjU3NjYzIDExLjI1IDEuODc1IDExLjI1SDEwLjEyNUMxMC40MjM0IDExLjI1IDEwLjcwOTUgMTEuMTMxNSAxMC45MjA1IDEwLjkyMDVDMTEuMTMxNSAxMC43MDk1IDExLjI1IDEwLjQyMzQgMTEuMjUgMTAuMTI1VjEuODc1QzExLjI1IDEuNTc2NjMgMTEuMTMxNSAxLjI5MDQ4IDEwLjkyMDUgMS4wNzk1QzEwLjcwOTUgMC44Njg1MjYgMTAuNDIzNCAwLjc1IDEwLjEyNSAwLjc1Wk05Ljc0MDg2IDcuOTUzOThMOS4zODkzIDkuNDc3NDJDOS4zNzE0NCA5LjU1NDg3IDkuMzI3ODYgOS42MjM5OSA5LjI2NTY3IDkuNjczNDlDOS4yMDM0OSA5LjcyMyA5LjEyNjM2IDkuNzQ5OTcgOS4wNDY4OCA5Ljc1QzUuMjk2ODggOS43NSAyLjI1IDYuNzA5OTIgMi4yNSAyLjk1MzEyQzIuMjUyNzkgMi44NzQzOSAyLjI4MDc4IDIuNzk4NjIgMi4zMjk4NSAyLjczNjk4QzIuMzc4OTEgMi42NzUzNCAyLjQ0NjQ3IDIuNjMxMDggMi41MjI1OCAyLjYxMDdMNC4wNDYwMiAyLjI1OTE0QzQuMDcyIDIuMjUzNjUgNC4wOTg0NCAyLjI1MDU5IDQuMTI1IDIuMjVDNC4xOTI5OCAyLjI1MzQzIDQuMjU4ODEgMi4yNzUgNC4zMTU2NSAyLjMxMjQ2QzQuMzcyNDggMi4zNDk5MyA0LjQxODI1IDIuNDAxOTIgNC40NDgyIDIuNDYzMDVMNS4xNTEzMyA0LjEwMzY3QzUuMTY4NTUgNC4xNDc5MSA1LjE3ODE0IDQuMTk0NzQgNS4xNzk2OSA0LjI0MjE5QzUuMTczOTYgNC4zNDYyNiA1LjEyNzY5IDQuNDQzOTUgNS4wNTA3OCA0LjUxNDNMNC4xNjI3MyA1LjI0MDg2QzQuNzAwODIgNi4zODEzMSA1LjYxODY5IDcuMjk5MTggNi43NTkxNCA3LjgzNzI3TDcuNDg1NyA2Ljk0OTIyQzcuNTU2MDUgNi44NzIzMSA3LjY1Mzc0IDYuODI2MDQgNy43NTc4MSA2LjgyMDMxQzcuODA1MjYgNi44MjE4NCA3Ljg1MjEgNi44MzE0MyA3Ljg5NjMzIDYuODQ4NjdMOS41MzY5NSA3LjU1MThDOS41OTgxMSA3LjU4MTcxIDkuNjUwMTMgNy42Mjc0NyA5LjY4NzYgNy42ODQzMUM5LjcyNTA3IDcuNzQxMTYgOS43NDY2MiA3LjgwNyA5Ljc1IDcuODc1QzkuNzQ5NTMgNy45MDE1NiA5Ljc0NjQ3IDcuOTI4MDIgOS43NDA4NiA3Ljk1Mzk4WiIgZmlsbD0iYmxhY2siLz48L3N2Zz4=)
            center center no-repeat;
        }
      }
    }

    &__btn {
      ${(props) => props.theme.buttons.primary};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: auto;
      text-align: center;
    }
  }
`;

const FooterMain = () => {
  const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState(false);

  const closeStateHandler = () => {
    setIsFeedbackFormOpen(false);
  };

  const socialIcons = [
    {
      link: "https://instagram.com/kasharwanda",
      icon: "/icons/instagram.svg",
      colorClass: "insta-colors",
    },
    {
      link: "https://www.facebook.com/kasharw",
      icon: "/icons/facebook.svg",
      colorClass: "fb-colors",
    },
    {
      link: "https://twitter.com/KashaGlobal",
      icon: "/icons/twitter.svg",
      colorClass: "twitter-colors",
    },
  ];

  return (
    <Wrapper className="footer-main">
      <Container>
        <div className="row">
          {isFeedbackFormOpen && (
            <FeedbackForm closeStateHandler={closeStateHandler} />
          )}
          <div className={`col-sm-12 col-lg-3`}>
            <div className={`${fm}__col`}>
              <div>
                <Typography variant={"h6"} type={"h6"}>
                  Contact Us
                </Typography>
                <div className={`${fm}__contact-us`}>
                  <Typography variant={"p"} type={"p"}>
                    WhatsApp:{" "}
                    <a href="https://wa.me/250734565045" target="_blank">
                      +250734565045
                    </a>
                  </Typography>
                  <Typography variant={"p"} type={"p"}>
                    Phone:{" "}
                    <a href="tel:250734565045" target="_blank">
                      +250734565045
                    </a>
                  </Typography>
                  <Typography variant={"p"} type={"p"}>
                    USSD: *911#
                  </Typography>
                  <Typography variant={"p"} type={"p"}>
                    Email:{" "}
                    <a
                      href="mailto:pharmarwanda@kasha.co"
                      className="btn-feedback"
                      target="_blank"
                    >
                      pharmarwanda@kasha.co
                    </a>
                  </Typography>

                  <br />

                  <Typography variant={"h6"} type={"h6"}>
                    Operating Hours
                  </Typography>
                  <Typography variant={"p"} type={"p"}>
                    Monday to Friday: 8:00am - 5:00pm
                  </Typography>
                  <Typography variant={"p"} type={"p"}>
                    Weekend and Holidays: 8:00am - 2:00pm
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className={`col-sm-12 col-lg-3`}>
            <div className={`${fm}__col `}>
              <div>
                <Typography variant={"h6"} type={"h6"}>
                  Pharmacy Warehouse
                </Typography>
                <Typography variant={"p"} type={"p"}>
                  Special Economic Zone - Atlantic Building Kigali,Gasabo
                </Typography>
                <Typography variant={"p"} type={"p"}>
                  Registration No. 10642712361243
                  <br />
                  <a href="tel:250734565045" target="_blank">
                    +250734565045
                  </a>
                  <br />
                  <a
                    href="mailto:pharmarwanda@kasha.co"
                    className="btn-feedback"
                    target="_blank"
                  >
                    pharmarwanda@kasha.co
                  </a>
                </Typography>
				<Typography
                  variant={"p"} type={"p"}
                >
                   <a href={"/Kasha Rwanda Wholesale Licence.pdf"} target="_blank">
                    Wholesale License
                  </a>
                </Typography>
                <br />
                <Typography variant={"p"} type={"p"}>
                  <b>Responsible Pharmacist</b>
                  <br />
                  Phn. Jean Claude Tuyishime
                  <br />
                  Registration No. NPC/AO578
                </Typography>

                <br />
              </div>
            </div>
          </div>
          <div className={`col-sm-12 col-lg-3`}>
            <div className={`${fm}__col pay-by-phone`}>
              <div>
                <Typography variant={"h6"} type={"h6"}>
                  Pay by phone
                </Typography>
                <Typography variant={"p"} type={"p"}>
                  Order easily &amp; pay over the phone with one of our Kasha
                  personal shoppers.
                </Typography>
                <br />
                <Typography variant={"h6"} type={"h6"}>
                  Legal
                </Typography>

                <Typography variant={"p"} type={"p"}>
                  {process.env.NEXT_PUBLIC_COUNTRY_CODE === "KE" && (
                    <Link href="https://kasha.co.ke/pp.pdf" target="_blank">
                      Privacy Policy
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_COUNTRY_CODE === "RW" && (
                    <Link href="https://kasha.rw/pp.pdf" target="_blank">
                      Privacy Policy
                    </Link>
                  )}
                </Typography>
                <Typography variant={"p"} type={"p"}>
                  {process.env.NEXT_PUBLIC_COUNTRY_CODE === "KE" && (
                    <Link href="https://kasha.co.ke/tc.pdf" target="_blank">
                      Terms and Conditions
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_COUNTRY_CODE === "RW" && (
                    <Link href="https://kasha.rw/tc.pdf" target="_blank">
                      Terms and Conditions
                    </Link>
                  )}
                </Typography>

                <br />
                <Typography variant={"h6"} type={"h6"}>
                  Information
                </Typography>
                <Typography variant={"p"} type={"p"}>
                  <Link href={"#"}>FAQs</Link>
                </Typography>
                <Typography variant={"p"} type={"p"}>
                  <Link href={"#"}>Blog</Link>
                </Typography>

                <br />
                <Typography variant={"h6"} type={"h6"}>
                  Feedback
                </Typography>
                <Typography variant={"p"} type={"p"}>
                  Your comments and suggestions help us improve our website and
                  service for you.
                </Typography>
                <br />
                <Typography variant={"p"} type={"p"}>
                  Please let us know what products you would like to see on our
                  website, so we can source them for you.
                </Typography>
                <br />
                {!isFeedbackFormOpen && (
                  <Button
                    title={"Feedback"}
                    className={`${fm}__btn w-50 rounded`}
                    type="button"
                    onClick={() => setIsFeedbackFormOpen(true)}
                  />
                )}
                <div className="d-flex my-4 justify-content-between w-50 align-items-center">
                  {(socialIcons || []).map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="d-flex rounded-circle bg-light justify-content-center align-items-center"
                        style={{
                          height: "40px",
                          width: "40px",
                        }}
                      >
                        <a
                          target="_blank"
                          href={item?.link}
                          style={{
                            width: "50%",
                            height: "50%",
                          }}
                        >
                          <Image
                            src={item?.icon}
                            alt={"Social icon"}
                            title={"Social icon"}
                            className={item?.colorClass}
                            quality={90}
                            width="0"
                            height="0"
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className={`col-sm-12 col-lg-3`}>
            <div className={`${fm}__col`}>
              <div>
                <Typography variant={"h6"} type={"h6"}>
                  Kasha
                </Typography>
                <Typography variant={"p"} type={"p"}>
                  <Link href={"https://kasha.co"} target="_blank">
                    About Us
                  </Link>
                </Typography>

                <Typography variant={"p"} type={"p"}>
                  <Link
                    href={"https://kashainc.bamboohr.com/careers"}
                    target="_blank"
                  >
                    Careers
                  </Link>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default FooterMain;
