'use client';

import React, { useEffect, useState } from 'react';
import Footer from '../footer';
import styled from '@emotion/styled';
import Head from 'next/head';
import { TopBar } from '../topbar/TopBar';
import { NavBar } from '../navbar/Navbar';
import CartPop from '../PopUp/CartPop';
import NotificationPop from '../PopUp/NotificationPop';
import { usePathname } from 'next/navigation';
import CategoriesMenuLoader from '@/components/CategoryMenus/CategoryMenuWrapper';
import CategoryWiseProducts from '@/components/CategorywiseProducts/CategorywiseProducts';
import { PreLoader } from '@/components/preloader/PreLoader';

const LayoutBlock = styled.div`
	width: 100%;
`;

const FixedTop = styled.div`
	position: relative;
	z-index: 100;
	width: 100%;
	transition: all 0.3s ease;

	&.fixed {
		position: fixed;
		top: 0;
		z-index: 100;
	}

	opacity: 0.97;
`;

export default function Layout({
	children,
	mainColor = '',
	headerColor = '',
	footerColor = '',
	handleBackToHome,
}) {
	const [isCartOpen, setIsCartOpen] = useState(false);
	const [isNotificationOpen, setIsNotificationOpen] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);

	// <------------ Categorywise Bar ---------------->
	const pathname = usePathname();
	const [isCategoryWise, setIsCategoryWise] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [categoryWiseProducts, setCategoryWiseProducts] = useState([]);
	const [categoryTitle, setCategoryTitle] = useState('');

	const handleMenuCategoryLink = (title) => {
		const userData = JSON.parse(localStorage.getItem('pharma_customer'));

		let apiUrl;
		if (title === 'Respiratory Health') {
			apiUrl = `${process.env.NEXT_PUBLIC_BACKEND_URL}${
				process.env.NEXT_PUBLIC_PHARMA_ALL_PRODUCTS
			}?category=B13&country=${process.env.NEXT_PUBLIC_COUNTRY_CODE}&location=${
				userData ? userData?.location : ''
			}`;
		} else if (title === 'Infectious Diseases') {
			apiUrl = `${process.env.NEXT_PUBLIC_BACKEND_URL}${
				process.env.NEXT_PUBLIC_PHARMA_ALL_PRODUCTS
			}?category=B14&country=${process.env.NEXT_PUBLIC_COUNTRY_CODE}&location=${
				userData ? userData?.location : ''
			}`;
		} else if (title === 'Reproductive Health') {
			apiUrl = `${process.env.NEXT_PUBLIC_BACKEND_URL}${
				process.env.NEXT_PUBLIC_PHARMA_ALL_PRODUCTS
			}?category=B15&country=${process.env.NEXT_PUBLIC_COUNTRY_CODE}&location=${
				userData ? userData?.location : ''
			}`;
		} else if (title === 'Dermatological Care') {
			apiUrl = `${process.env.NEXT_PUBLIC_BACKEND_URL}${
				process.env.NEXT_PUBLIC_PHARMA_ALL_PRODUCTS
			}?category=B16&country=${process.env.NEXT_PUBLIC_COUNTRY_CODE}&location=${
				userData ? userData?.location : ''
			}`;
		} else if (title === 'Eye & Oral Care') {
			apiUrl = `${process.env.NEXT_PUBLIC_BACKEND_URL}${
				process.env.NEXT_PUBLIC_PHARMA_ALL_PRODUCTS
			}?category=B17&country=${process.env.NEXT_PUBLIC_COUNTRY_CODE}&location=${
				userData ? userData?.location : ''
			}`;
		} else if (title === 'Pain Management') {
			apiUrl = `${process.env.NEXT_PUBLIC_BACKEND_URL}${
				process.env.NEXT_PUBLIC_PHARMA_ALL_PRODUCTS
			}?category=B18&country=${process.env.NEXT_PUBLIC_COUNTRY_CODE}&location=${
				userData ? userData?.location : ''
			}`;
		} else if (title === 'Chronic Diseases') {
			apiUrl = `${process.env.NEXT_PUBLIC_BACKEND_URL}${
				process.env.NEXT_PUBLIC_PHARMA_ALL_PRODUCTS
			}?category=B19&country=${process.env.NEXT_PUBLIC_COUNTRY_CODE}&location=${
				userData ? userData?.location : ''
			}`;
		}
		fetchProducts(apiUrl, title);
		setCategoryTitle(title);
		setIsCategoryWise(true);
	};

	const makeApiCall = async (apiUrl, title) => {
		try {
			const response = await fetch(apiUrl, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			if (!response.ok) {
				throw new Error(`Failed to ${title} items`);
			}
			return response.json();
		} catch (error) {
			console.error(`Error fetching ${title} items`, error);
			throw error;
		}
	};

	const fetchProducts = async (apiUrl, title) => {
		setIsLoading(true);

		const fetchedProducts = await makeApiCall(apiUrl, title);
		setCategoryWiseProducts(fetchedProducts.data);

		setIsLoading(false);
	};

	const handleBackToPage = () => {
		setIsCategoryWise(false);
		router.push('/');
	};

	const handleBackOnHome = () => {
		setCategoryTitle('');
		setCategoryWiseProducts([]);
		setIsCategoryWise(false);
	};
	// <------------ Categorywise Bar ---------------->

	const toggleCart = () => {
		setIsCartOpen(!isCartOpen);
	};

	const toggleNotification = () => {
		setIsNotificationOpen(!isNotificationOpen);
	};

	useEffect(() => {
		const handleScroll = () => {
			// Set the scroll distance at which the top div becomes fixed
			const offset = 100; // Adjust this value based on your needs
			setIsScrolled(window.pageYOffset > offset);
		};

		// Add scroll event listener
		window.addEventListener('scroll', handleScroll);

		// Clean up the event listener on component unmount
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<LayoutBlock className='site-wrapper'>
			<FixedTop className={`container-fluid p-0 ${isScrolled ? 'fixed' : ''}`}>
				<Head />
				<TopBar />
				<NavBar
					isOpen={isCartOpen}
					toggleCart={toggleCart}
					isNotificationOpen={isNotificationOpen}
					toggleNotification={toggleNotification}
					handleBackToHome={handleBackToHome}
				/>
			</FixedTop>

			{isCategoryWise ? (
				<div
				// handleBackToPage={handleBackToPage}
				>
					{isLoading ? (
						<PreLoader />
					) : (
						<CategoryWiseProducts
							categoryWiseProducts={categoryWiseProducts}
							categoryTitle={categoryTitle}
							handleBackOnHome={handleBackOnHome}
							handleMenuCategoryLink={handleMenuCategoryLink}
						/>
					)}
				</div>
			) : (
				pathname !== '/' && (
					<div className='container-fluid px-0'>
						<CategoriesMenuLoader
							handleMenuCategoryLink={handleMenuCategoryLink}
							categoryTitle={pathname.slice(1)}
						/>
					</div>
				)
			)}

			<div className='main-content-area'>
				{isCategoryWise ? null : children}
			</div>
			<CartPop isOpen={isCartOpen} toggleCart={toggleCart} />
			<NotificationPop
				isOpen={isNotificationOpen}
				toggleNotification={toggleNotification}
			/>
			<Footer footerColor={footerColor} />
		</LayoutBlock>
	);
}
