import { useEffect, useState } from 'react';
import Image from 'next/image';
import style from './pharma.module.css';
import Pusher from 'pusher-js';

export function PharmaProfile() {
	const [storedUserData, setStoredUserData] = useState(null);
	const [notificationCountKE, setNotificationCountKE] = useState(0);
	const [notificationCountRW, setNotificationCountRW] = useState(0);
	const [notificationMessage, setNotificationMessage] = useState('');
	// useEffect(() => {
	// 	const userData = JSON.parse(localStorage.getItem('pharma_customer'));

	// 	setStoredUserData(userData);
	// }, []);

	// Pusher Test
	// useEffect(() => {
	//   const ownerid = JSON.parse(localStorage.getItem("pharma_customer"));
	//   setStoredUserData(ownerid);
	//   const notificationMsg = localStorage.getItem("notifications_message");

	//   if (process.env.NEXT_PUBLIC_COUNTRY_CODE === "KE") {
	//     const storedNotificationCountKE =
	//       localStorage.getItem("notifications_KE");
	//     setNotificationCountKE(storedNotificationCountKE);
	//   } else {
	//     const storedNotificationCountRW =
	//       localStorage.getItem("notifications_RW");
	//     setNotificationCountRW(storedNotificationCountRW);
	//   }

	//   // if (storedNotificationCountKE) {
	//   // 	setNotificationCountKE(parseInt(storedNotificationCountKE, 10));
	//   // }
	//   // if (storedNotificationCountRW) {
	//   // 	setNotificationCountRW(parseInt(storedNotificationCountRW, 10));
	//   // }

	//   // Initialize Pusher with your credentials
	//   const pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_APP_KEY, {
	//     cluster: "mt1",
	//   });

	//   const channel = pusher.subscribe("kasha-cms-notification-development");
	//   // Below Cluster Name is bind with above channel name
	//   channel.bind("send-business-verified-pusher-notification", (data) => {
	//     // Handle new order event, update state, show notification, etc.
	//     if (data.user_id === ownerid?.id) {
	//       setNotificationCountKE((prevCount) => prevCount + 1);
	//       setNotificationCountRW((prevCount) => prevCount + 1);
	//       setNotificationMessage(notificationMsg);

	//       localStorage.setItem("notifications_KE", notificationCountKE + 1);
	//       localStorage.setItem("notifications_RW", notificationCountRW + 1);
	//     }
	//   });

	//   return () => {
	//     // Unsubscribe from the channel when component unmounts
	//     channel.unbind_all();
	//     channel.unsubscribe();
	//   };
	// }, [
	//   notificationCountKE,
	//   notificationCountRW,
	//   process.env.NEXT_PUBLIC_COUNTRY_CODE,
	// ]);

	return (
		<div className='global.font'>
			<div className={style.wrapper}>
				{storedUserData &&
					storedUserData.verification_status === 'approved' &&
					storedUserData.business_name && (
						<div>
							<div>
								<i
									style={{
										flexShrink: '0',
									}}>
									<Image
										src='/images/store.svg'
										alt='Email Icon'
										width={20}
										height={19}
									/>
								</i>
								{''} {storedUserData.business_name}
							</div>
							<div className={style.notes}>
								{/* {!storedUserData.token &&
                  process.env.NEXT_PUBLIC_COUNTRY_CODE === "KE" &&
                  (notificationCountKE === 0 ||
                    notificationCountKE === null) && <div>No Notification</div>} */}

								{/* | 3 pendings */}
							</div>
						</div>
					)}
			</div>
		</div>
	);
}

export default PharmaProfile;
