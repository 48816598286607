'use client';

import useCart from '@/utils/useCart';
import { useAppSelector } from '@/lib/hooks';
import style from '../../components/Products/ProductList.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import { useState, useEffect, useRef } from 'react';
import { PreLoader } from '@/components/preloader/PreLoader';
import CategoriesMenuLoader from '@/components/CategoryMenus/CategoryMenuWrapper';
import { useRouter } from 'next/navigation';
import ProductBox from '../Products/ProductBox';

export default function CategoryWiseProducts({
	categoryWiseProducts,
	categoryTitle,
	handleBackOnHome,
	handleMenuCategoryLink,
}) {
	const [screenWidth, setScreenWidth] = useState(
		typeof window !== 'undefined' ? window.innerWidth : null
	);
	const [isHovered, setIsHovered] = useState(false);
	const marqueeRef = useRef(null);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	const handleNextPage = () => {
		window.scrollTo(0, 0);
		if (currentPage < totalPages) {
			setCurrentPage((prevPage) => prevPage + 1);
		}
	};

	const handlePrevPage = () => {
		window.scrollTo(0, 0);
		if (currentPage > 1) {
			setCurrentPage((prevPage) => prevPage - 1);
		}
	};

	const handlePageChange = (pageNumber) => {
		window.scrollTo(0, 0);
		if (pageNumber >= 1 && pageNumber <= totalPages) {
			setCurrentPage(pageNumber);
		}
	};

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<>
			<div
				style={{
					marginRight: 'auto',
					marginLeft: 'auto',
					padding: '1rem',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
				}}>
				<div>
					<CategoriesMenuLoader
						handleMenuCategoryLink={handleMenuCategoryLink}
						categoryTitle={categoryTitle}
					/>
				</div>
				<div
					// style={{
					// 	display: 'flex',
					// 	alignItems: 'center',
					// 	justifyContent: 'flex-start',
					// 	fontSize: '12px',
					// 	fontWeight: '500',
					// 	marginBottom: '1rem',
					// 	width: '100%',
					// 	marginLeft: '12rem',
					// }}
					className={style.breadCrumb}>
					<div>
						<Link href='#' onClick={handleBackOnHome}>
							<Image
								src='/images/arrow-left-02-sharp.svg'
								height={20}
								width={20}
								alt='back'
								style={{ marginRight: '5px', cursor: 'pointer' }}
							/>
						</Link>
					</div>
					<div>Home / </div>&nbsp;
					<div style={{ color: '#e2156a' }}>{categoryTitle} Products</div>
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexWrap: 'wrap',
					}}>
					{isLoading ? (
						<PreLoader />
					) : (
						categoryWiseProducts &&
						categoryWiseProducts.map((product, index) => (
							<div key={index} style={{ height: '232px' }}>
								<ProductBox product={product} />
							</div>
						))
					)}
				</div>
				<div>
					<button onClick={handlePrevPage} disabled={currentPage === 1}>
						Previous
					</button>
					{Array.from({ length: totalPages }, (_, index) => index + 1).map(
						(pageNumber) => (
							<button
								key={pageNumber}
								style={
									pageNumber === currentPage
										? { backgroundColor: '#e2156a' }
										: null
								}
								onClick={() => handlePageChange(pageNumber)}>
								{pageNumber}
							</button>
						)
					)}
					<button
						onClick={handleNextPage}
						disabled={currentPage === totalPages}>
						Next
					</button>
				</div>
			</div>
		</>
	);
}
