import React from 'react'
import styled from '@emotion/styled'
import Button from '../Buttons/Button'
import dynamic from 'next/dynamic'
import DatePicker from 'react-multi-date-picker'
// import IntlTelInput from 'react-intl-tel-input'

const Select = dynamic(
	() => import('react-select').then((mod) => mod.default),
	{
		ssr: false,
		loading: () => null,
	}
);

export const InputWrapper = styled.div`
	.form-input {
		&__field {
			height: 44px;
			line-height: 44px;
			font-size: inherit;
			display: block;
			width: 100%;
			padding: 0.375rem 0.75rem;
			font-weight: 400;
			color: ${(props) => props.theme.colors.grey3};
			background-color: ${(props) => props.theme.colors.white};
			background-clip: padding-box;
			border: solid 1px ${(props) => props.theme.colors.grey2};
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			outline: none;
			border-radius: 0;
			transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

			&:focus {
				border-color: ${(props) => props.theme.colors.grey3};
				color: inherit;
			}
		}

		&__error {
			color: red;
			margin: 10px 0 0;
		}
	}

	&.hidden {
		display: none;
	}

	.selected-flag {
		display: flex !important;
		align-items: center !important;
		justify-content: center;
	}

	.selected-dial-code {
		padding-left: 10px !important;
	}

	.form-control {
		align-items: center;
		cursor: default;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		min-height: 44px;
		outline: none !important;
		position: relative;
		-webkit-transition: all 100ms;
		transition: all 100ms;
		background-color: hsl(0, 0%, 100%);
		border-color: hsl(0, 0%, 80%);
		border-radius: 0;
		border-style: solid;
		border-width: 1px;

		&:focus {
			border-color: #495057;
			color: inherit;
			box-shadow: none;
		}
	}
`;

const PasswordWrap = styled.div`
	position: relative;
`;

const ShowPassword = styled.button`
	border: none;
	background-color: transparent;
	width: 24px;
	height: 24px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 12px;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMiAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAuOTk5NyAwQzE2LjM5MTcgMCAyMC44Nzc3IDMuODggMjEuODE4NyA5QzIwLjg3ODcgMTQuMTIgMTYuMzkxNyAxOCAxMC45OTk3IDE4QzUuNjA3NjYgMTggMS4xMjE2NiAxNC4xMiAwLjE4MDY2NCA5QzEuMTIwNjYgMy44OCA1LjYwNzY2IDAgMTAuOTk5NyAwWk0xMC45OTk3IDE2QzEzLjAzOTEgMTUuOTk5NiAxNS4wMTgxIDE1LjMwNjggMTYuNjEyNSAxNC4wMzUyQzE4LjIwNyAxMi43NjM1IDE5LjMyMjYgMTAuOTg4MyAxOS43NzY3IDlDMTkuMzIwOSA3LjAxMzMgMTguMjA0NiA1LjI0IDE2LjYxMDMgMy45NzAwM0MxNS4wMTYgMi43MDAwNSAxMy4wMzggMi4wMDg1MyAxMC45OTk3IDIuMDA4NTNDOC45NjEzNiAyLjAwODUzIDYuOTgzMzUgMi43MDAwNSA1LjM4OTA0IDMuOTcwMDNDMy43OTQ3MyA1LjI0IDIuNjc4NDMgNy4wMTMzIDIuMjIyNjYgOUMyLjY3Njc2IDEwLjk4ODMgMy43OTIzNCAxMi43NjM1IDUuMzg2ODEgMTQuMDM1MkM2Ljk4MTI4IDE1LjMwNjggOC45NjAyIDE1Ljk5OTYgMTAuOTk5NyAxNlpNMTAuOTk5NyAxMy41QzkuODA2MTkgMTMuNSA4LjY2MTYgMTMuMDI1OSA3LjgxNzY4IDEyLjE4MkM2Ljk3Mzc3IDExLjMzODEgNi40OTk2NiAxMC4xOTM1IDYuNDk5NjYgOUM2LjQ5OTY2IDcuODA2NTMgNi45NzM3NyA2LjY2MTkzIDcuODE3NjggNS44MTgwMkM4LjY2MTYgNC45NzQxMSA5LjgwNjE5IDQuNSAxMC45OTk3IDQuNUMxMi4xOTMxIDQuNSAxMy4zMzc3IDQuOTc0MTEgMTQuMTgxNiA1LjgxODAyQzE1LjAyNTYgNi42NjE5MyAxNS40OTk3IDcuODA2NTMgMTUuNDk5NyA5QzE1LjQ5OTcgMTAuMTkzNSAxNS4wMjU2IDExLjMzODEgMTQuMTgxNiAxMi4xODJDMTMuMzM3NyAxMy4wMjU5IDEyLjE5MzEgMTMuNSAxMC45OTk3IDEzLjVaTTEwLjk5OTcgMTEuNUMxMS42NjI3IDExLjUgMTIuMjk4NiAxMS4yMzY2IDEyLjc2NzQgMTAuNzY3OEMxMy4yMzYzIDEwLjI5ODkgMTMuNDk5NyA5LjY2MzA0IDEzLjQ5OTcgOUMxMy40OTk3IDguMzM2OTYgMTMuMjM2MyA3LjcwMTA3IDEyLjc2NzQgNy4yMzIyM0MxMi4yOTg2IDYuNzYzMzkgMTEuNjYyNyA2LjUgMTAuOTk5NyA2LjVDMTAuMzM2NiA2LjUgOS43MDA3NCA2Ljc2MzM5IDkuMjMxOSA3LjIzMjIzQzguNzYzMDYgNy43MDEwNyA4LjQ5OTY2IDguMzM2OTYgOC40OTk2NiA5QzguNDk5NjYgOS42NjMwNCA4Ljc2MzA2IDEwLjI5ODkgOS4yMzE5IDEwLjc2NzhDOS43MDA3NCAxMS4yMzY2IDEwLjMzNjYgMTEuNSAxMC45OTk3IDExLjVaIiBmaWxsPSIjMzUzOTQ1Ii8+PC9zdmc+);
`;

// Custom styles for Styled Select
// Documentation can be found here - https://react-select.com/styles
const customStyles = {
	control: (provided, state) => ({
		...provided,
		minHeight: '44px',
		background: state.isDisabled ? '#E9ECEF' : '#fff',
		border: '1px solid #d8d8d8',
		borderRadius: '0px',
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		display: 'none',
	}),
	menuList: (provided, state) => ({
		...provided,
		padding: 8,
	}),
	option: (provided, state) => ({
		...provided,
		fontFamily: 'Poppins, sans-serif',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '16px',
		lineHeight: 1.5,
		color: state.isSelected || state.isFocused ? '#23262F' : '#777E90',
		background: state.isSelected || state.isFocused ? '#F4F5F6' : '#FCFCFD',
		padding: '12px',
	}),
};

const CustomInput = ({
	openCalendar,
	value,
	handleValueChange,
	placeholder = '',
	isRequired = false,
	isReadonly = false,
	className = '',
}) => {
	return (
		<input
			className={`form-input__field ${className}`}
			name={'dates'}
			onFocus={openCalendar}
			value={value}
			onChange={handleValueChange}
			placeholder={placeholder}
			required={isRequired}
			readOnly={isReadonly}
		/>
	);
};

const InputForm = ({ data, error = {} }) => {
	const selectLabel = (option) => {
		return <span className='label'>{option?.label}</span>;
	};

	/**
	 * Define what type of input should be rendered
	 * @param param
	 * @return {*}
	 */
	const renderSwitch = (param) => {
		const isRequiredField = param?.isRequired ? { required: true } : {};

		switch (param?.type) {
			case 'submit':
				return (
					<Button
						className={`${param?.className} ${param?.classParent} `}
						name={param?.name}
						type={param?.type}
						title={param?.title}
						image={param?.image}
						defaultValue={param?.defaultValue}
						btnSize={param?.btnSize}
						disabled={param?.disabled}
					/>
				);
			case 'select':
				return (
					<Select
						options={param?.options}
						styles={customStyles}
						className={'form-input__select-wrapper'}
						name={'selected-tours'}
						isMulti={param?.multi ? param.multi : false}
						isSearchable={true}
						onChange={param?.onChange}
						isDisabled={param?.isDisabled}
						// value={param?.value}
						defaultValue={
							param?.defaultValue
								? {
										value: param?.defaultValue?.value,
										label: param?.defaultValue?.label,
								  }
								: ''
						}
					/>
				);
			case 'select-one':
				return (
					<Select
						options={param?.options}
						styles={param?.styles ? param.styles : customStyles}
						className={param?.className}
						name={param?.name}
						value={param?.value}
						defaultValue={
							param?.defaultValue
								? {
										value: param?.defaultValue?.value,
										label: param?.defaultValue?.label,
										symbol: param?.defaultValue?.symbol,
								  }
								: ''
						}
						isMulti={param?.multi ? param.multi : false}
						isSearchable={param?.searchable ? param.searchable : false}
						onChange={param?.onChange}
						getOptionLabel={(option) => selectLabel(option)}
					/>
				);
			case 'text':
				return (
					<input
						className={`form-input__field ${param?.className}`}
						name={param?.name}
						type={param?.type}
						disabled={param?.disabled}
						ref={param?.ref}
						{...isRequiredField}
						defaultValue={param?.defaultValue}
						autoComplete={'off'}
						placeholder={param?.placeholder}
						onChange={param?.onChange ? param?.onChange : () => {}}
						onBlur={param?.onBlur ? param?.onBlur : () => {}}
						style={param?.disabled ? { backgroundColor: '#E9ECEF' } : null}
						required
					/>
				);
			case 'tel':
			case 'number':
			case 'email':
				return (
					<input
						className={`form-input__field ${param?.className}`}
						name={param?.name}
						type={param?.type}
						disabled={param?.disabled}
						ref={param?.ref}
						{...isRequiredField}
						defaultValue={param?.defaultValue}
						onChange={param?.onChange ? param?.onChange : () => {}}
						placeholder={param?.placeholder}
						onBlur={param?.onBlur ? param?.onBlur : () => {}}
						style={param?.disabled ? { backgroundColor: '#E9ECEF' } : null}
					/>
				);

			case 'phone':
				// return <IntlTelInput
				//   required
				//   containerClassName="intl-tel-input"
				//   defaultCountry={'ke'}
				//   preferredCountries={[]}
				//   onlyCountries={['ke']}
				//   initialCountry={'ke'}
				//   inputClassName={`form-control ${param?.className}`}
				//   separateDialCode={true}
				//   value={param?.defaultValue}
				//   onPhoneNumberChange={param?.onChange}
				//   onPhoneNumberBlur={param?.onBlur}

				// />
				return null;
			case 'datapicker':
				return (
					<DatePicker
						numberOfMonths={param.numberOfMonths ? param.numberOfMonths : 1}
						disableMonthPicker={
							param.disableMonthPicker ? param.disableMonthPicker : false
						}
						disableYearPicker={
							param.disableYearPicker ? param.disableYearPicker : false
						}
						range={param?.range}
						mapDays={({ date }) => {
							let isWeekend = [0, 6].includes(date.weekDay.index);
							let today = new Date();
							let currentDate = new Date(date.year, date.month - 1, date.day);
							let isToday = currentDate.toDateString() === today.toDateString();

							if (isWeekend || isToday)
								return {
									disabled: true,
									style: { color: '#8798ad' },
								};
						}}
						render={
							<CustomInput
								placeholder={param?.placeholder}
								className={`${param?.className}`}
								isRequired={param?.isRequired}
								isReadonly={param?.isReadonly}
							/>
						}
						format={'DD/MM/YYYY'}
						className='form-input__calendar'
						clearable={param.isClearable ? param.isClearable : 'false'}
						value={param?.value}
						onKeyDown={(e) => {
							e.preventDefault();
						}}
						onChange={param?.onChange}
						minDate={param?.minDate ? param?.minDate : Date.now()}
						maxDate={param?.maxDate}
					/>
				);

			case 'password':
				return (
					<PasswordWrap className='js-password-wrap'>
						<input
							className={`form-input__field ${param?.className}`}
							name={param?.name}
							type={param?.type}
							ref={param?.ref}
							{...isRequiredField}
							autoComplete='new-password'
							defaultValue={param?.defaultValue}
							onChange={param?.onChange}
							placeholder={param?.placeholder}
							onBlur={param?.onBlur ? param?.onBlur : () => {}}
						/>

						{param?.showPasswordButton && (
							<ShowPassword
								onClick={showPasswordHandler}
								className='form-input__show-password-icon'
							/>
						)}
					</PasswordWrap>
				);

			case 'textarea':
				return (
					<textarea
						className={`form-input__field ${param?.className}`}
						name={param?.name}
						disabled={param?.disabled}
						style={param?.disabled ? { backgroundColor: '#E9ECEF' } : null}
						ref={param?.ref}
						{...isRequiredField}
						defaultValue={param?.defaultValue}
						onChange={param?.onChange ? param?.onChange : () => {}}
						placeholder={param?.placeholder}
						onBlur={param?.onBlur ? param?.onBlur : () => {}}
						required
					/>
				);

			case 'checkbox':
				return (
					<>
						<input
							id={param?.id}
							className={`${param?.className}`}
							name={param?.name}
							ref={param?.ref}
							type={param?.type}
							{...isRequiredField}
							value={param?.value}
							defaultChecked={param?.checked}
							onClick={param?.change}
						/>
						<label
							htmlFor={param?.id}
							className={`${param?.checkboxLabelClass || ''}__field-label`}
							dangerouslySetInnerHTML={{ __html: param?.checkboxLabel }}
						/>
					</>
				);

			case 'radio':
				return (
					<>
						<input
							id={param?.id}
							className={`${param?.className}`}
							name={param?.name}
							ref={param?.ref}
							type={param?.type}
							value={param?.value}
							{...isRequiredField}
							defaultChecked={param?.defaultChecked}
							onClick={param?.change}
						/>
						<label
							htmlFor={param?.id}
							className={`${param?.checkboxLabelClass}__field-label ${param?.checkboxLabelClass}`}
							dangerouslySetInnerHTML={{ __html: param?.checkboxLabel }}
						/>
					</>
				);

			case 'reset':
				return (
					<input
						id={param?.id}
						className={`form-input__field ${param?.className}`}
						type={param?.type}
						ref={param?.ref}
						value={param?.title}
						onClick={param?.onClick}
					/>
				);

			case 'file':
				return (
					<>
						<input
							id={param?.id}
							className={`form-input__field ${param?.className}`}
							name={param?.name}
							ref={param?.ref}
							type={param?.type}
							onChange={param?.onChange}
						/>
						<label htmlFor={param?.id} className={`${param?.fileLabelClass}`}>
							{param?.fileLabel}
						</label>
					</>
				);

			case 'hidden':
				return (
					<input
						id={param?.id}
						type={param?.type}
						name={param?.name}
						value={param?.value}
						onChange={param?.onChange}
					/>
				);

			case 'forgot-password':
				return (
					<button
						className={`${param?.className} btn-default`}
						onClick={param?.onClickHandler}>
						{param?.placeholder}
					</button>
				);
		}
	};

	return (
		<InputWrapper
			className={`${data?.classParent ? data?.classParent : ''} ${
				data?.type
			} form-input ${error?.error ? 'error' : ''}`}>
			{data?.label && (
				<label htmlFor={data?.name} className='form-input__label'>
					{data?.label}
					{data?.required && <span className='required'>*</span>}
					{data?.optional && <span className='optional'>(OPTIONAL)</span>}
				</label>
			)}

			{renderSwitch(data)}

			{error && !error?.errorText && (
				<p className='form-input__error'>{error[0]}</p>
			)}

			{error?.error && error?.errorText && (
				<p className='form-input__error'>{error?.errorText}</p>
			)}
		</InputWrapper>
	);
};

export default InputForm;
