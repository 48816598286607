'use client';

import { useAppSelector } from '@/lib/hooks';
import style from './CartPop.module.scss';
import Image from 'next/image';
import { useState, useEffect, useRef } from 'react';
import CartItems from './CartItems';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';

const CartPop = ({ isOpen, toggleCart }) => {
	const pathname = usePathname();
	const searchParams = useSearchParams();
	const search = searchParams.get('id');

	const [userProfile, setUserProfile] = useState([]);
	const [isLoading, setIsloading] = useState(false);
	const [isAccountVerified, setIsAccountVerified] = useState('');

	const [isActive, setIsActive] = useState(isOpen);
	const cartItems = useAppSelector((state) => state.cartItems.cartItems);

	const userDetails =
		typeof window !== 'undefined' &&
		JSON.parse(localStorage.getItem('pharma_customer'));

	const cartRef = useRef(null);

	const [isClient, setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	//	For outside click
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (cartRef.current && !cartRef.current.contains(event.target)) {
				toggleCart(false);
			}
		};

		if (isOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isOpen, toggleCart]);
	//	For outside click

	useEffect(() => {
		setIsActive(isOpen);
	}, [isOpen]);

	useEffect(() => {
		const shoppingCarts = document.querySelectorAll(`.${style.shoppingCart}`);
		shoppingCarts.forEach((cart, index) => {
			const delay = index * 50 + 'ms';
			cart.style.transitionDelay = delay;
			cart.style.zIndex = 10;
		});
	}, []); // Empty dependency array ensures this effect runs only once on mount

	useEffect(() => {
		fetchUserProfile();
	}, []);

	const fetchUserProfile = async () => {
		const userData = JSON.parse(localStorage.getItem('pharma_customer'));
		setIsAccountVerified(userData?.business_name);

		const apiUrl = `${process.env.NEXT_PUBLIC_CMS_URL}${process.env.NEXT_PUBLIC_PROFILE_API_PATH}`;

		if (userDetails?.token) {
			setIsloading(true);
			await fetch(apiUrl, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userDetails?.token}`,
				},
			})
				.then((response) => response.json())
				.then((result) => {
					// Extracting the locations array from the response object
					const profileData = result && result.data ? result.data : [];

					if (profileData) {
						setUserProfile(profileData);
						localStorage.setItem(
							'after_verification',
							profileData.verification_status
						);
						setIsloading(false);
					} else {
						console.error('Invalid profile data:', result);
						setIsloading(false);
					}
				})
				.catch((error) => {
					console.error('Fetch error:', error);
				});
		}
	};

	const handleApprovalStatus = async () => {
		fetchUserProfile();
	};

	return (
		<>
			{isClient ? (
				<div
					className={`${style.shoppingCart} ${isActive ? style.active : ''}`}
					ref={cartRef}>
					<div className={style.shoppingCartHeader}>
						<div>Shopping Cart</div>
						<Image
							src='/images/cancel.svg'
							width={18}
							height={18}
							style={{ cursor: 'pointer' }}
							alt='Cancel'
							onClick={() => toggleCart(false)}
						/>
					</div>
					<div className={style.shoppingCartItems}>
						<CartItems isActive={isActive} />
					</div>
					{userDetails?.id && cartItems && cartItems?.total > 0 ? (
						<div className={style.subTotal}>
							<div>Total</div>
							<div style={{ marginRight: '1rem' }}>
								{process.env.NEXT_PUBLIC_COUNTRY_CODE === 'KE' ? 'KES' : 'RWF'}{' '}
								{cartItems?.total.toLocaleString()}
							</div>
						</div>
					) : userDetails?.id ? (
						<div style={{ textAlign: 'center', fontSize: '12px' }}>
							Your cart is empty.
						</div>
					) : null}

					{/* {userDetails && userDetails.verification_status === 'approved' ? ( */}
					{isLoading ? (
						'Checking...'
					) : userProfile &&
					  userProfile?.verification_status === 'approved' &&
					  cartItems?.total > 0 ? (
						<Link href='/checkout' className={style.button}>
							Checkout
						</Link>
					) : (
						<div className={style.footer}>
							{userProfile?.verification_status !== 'approved' && (
								<Image
									src='/images/alert-circle.svg'
									height={18}
									width={18}
									alt='alert'
								/>
							)}
							{userProfile?.length === 0 ? (
								<span style={{ fontSize: '12px', fontWeight: '600' }}>
									To checkout, please &nbsp;
									<Link
										href='/login'
										style={{ textDecoration: 'none', color: '#1E499F' }}>
										login or register
									</Link>{' '}
									here
								</span>
							) : !isAccountVerified ? (
								<span
									style={{
										fontSize: '12px',
										fontWeight: '600',
									}}>
									Please add your Business Details{' '}
									<Link href='/business' style={{ textDecoration: 'none' }}>
										here
									</Link>{' '}
									to complete the Registration process.
								</span>
							) : (
								userProfile?.verification_status !== 'approved' && (
									<span style={{ fontSize: '12px', fontWeight: '600' }}>
										Your account is pending approval, but we are on it. &nbsp;
										<Link
											// href='#'
											href={{
												pathname: pathname,
												query: pathname.includes('/details')
													? {
															id: search,
															country: process.env.NEXT_PUBLIC_COUNTRY_CODE,
													  }
													: { status: Math.random() },
											}}
											style={{ textDecoration: 'none', color: '#1E499F' }}
											onClick={handleApprovalStatus}>
											Click here
										</Link>{' '}
										to refresh verification status.
									</span>
								)
							)}
						</div>
					)}
				</div>
			) : null}
		</>
	);
};

export default CartPop;
