import { useAppSelector } from '@/lib/hooks';
import useCart from '@/utils/useCart';
import Image from 'next/image';
import React, { useState, useEffect } from 'react';
import style from './CartPop.module.scss';
import useDebounce from '@/utils/useDebounce';
import { usePathname } from 'next/navigation';
import Cookies from 'js-cookie';

const CartItems = ({ isActive }) => {
	const cookieToken = Cookies.get('token');

	const pathname = usePathname();
	const {
		reduceProductQuantity,
		increaseProductQuantity,
		removeProduct,
		manualProductQuantity,
	} = useCart();
	const cartItems = useAppSelector((state) => state.cartItems.cartItems);
	const [quantities, setQuantities] = useState({});
	const [lastValidQuantities, setLastValidQuantities] = useState({});
	const [isCheckoutQuantity, setIsCheckoutQuantity] = useState(false);

	const debouncedQuantities = useDebounce(quantities, 100);

	// useEffect(() => {
	// 	if (cartItems.products && Object.keys(quantities).length === 0) {
	// 		const initialQuantities = {};
	// 		const initialLastValidQuantities = {};

	// 		cartItems.products.forEach((item) => {
	// 			initialQuantities[item.id] = item.quantity.toString();
	// 			initialLastValidQuantities[item.id] = item.quantity.toString();
	// 		});

	// 		setQuantities(initialQuantities);
	// 		setLastValidQuantities(initialLastValidQuantities);
	// 	}
	// }, [cartItems, quantities]);

	useEffect(() => {
		if (cartItems.products && Object.keys(quantities).length === 0) {
			setQuantities((prevQuantities) => {
				const initialQuantities = {};
				const initialLastValidQuantities = {};

				cartItems.products.forEach((item) => {
					initialQuantities[item.id] = item.quantity.toString();
					initialLastValidQuantities[item.id] = item.quantity.toString();
				});

				return initialQuantities;
			});

			setLastValidQuantities((prevLastValidQuantities) => {
				const initialLastValidQuantities = {};

				cartItems.products.forEach((item) => {
					initialLastValidQuantities[item.id] = item.quantity.toString();
				});

				return initialLastValidQuantities;
			});
		}
	}, [cartItems]);

	useEffect(() => {
		Object.keys(debouncedQuantities).forEach((id) => {
			const product = cartItems.products.find((item) => item.id === id);
			if (product) {
				const newQuantity = parseInt(debouncedQuantities[id], 10);
				if (
					!isNaN(newQuantity) &&
					newQuantity >= 1
					// && newQuantity <= product.total_inventory
				) {
					manualProductQuantity(product, newQuantity);
				}
			}
		});
	}, [debouncedQuantities]);

	function Camelize(str) {
		if (typeof str !== 'string') {
			return '';
		}

		return str
			.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
				return index === 0 ? word.toUpperCase() : word.toLowerCase();
			})
			.replace(/\s+/g, '');
	}

	const handleChangeQuantity = (event, product) => {
		const value = event.target.value;

		if (value === '' || /^[0-9]*$/.test(value)) {
			const newQuantities = { ...quantities };
			newQuantities[product.id] = value;

			const newQuantity = parseInt(value, 10);

			if (
				!isNaN(newQuantity) &&
				newQuantity >= 1
				// &&
				// newQuantity <= product.total_inventory
			) {
				setLastValidQuantities((prevLastValidQuantities) => ({
					...prevLastValidQuantities,
					[product.id]: value,
				}));
				// setTimeout(() => {
				//     manualProductQuantity(product, newQuantity);
				// }, 500);
			} else if (!isNaN(newQuantity) && newQuantity === 0) {
				removeProduct(product);
			}

			setQuantities(newQuantities);
		}
	};

	const handleBlurQuantity = (event, product) => {
		setIsCheckoutQuantity(false);
		const value = event.target.value;

		if (value === '') {
			const newQuantities = { ...quantities };
			newQuantities[product.id] = lastValidQuantities[product.id];
			setQuantities(newQuantities);
		}
	};

	const handleIncreaseQuantity = (item) => {
		if (item.quantity < 9999) {
			increaseProductQuantity(item);
		}
		// setQuantities((prevQuantities) => ({
		// 	...prevQuantities,
		// 	[item.id]: (parseInt(prevQuantities[item.id], 10) + 1).toString(),
		// }));
	};

	const handleDecreaseQuantity = (item) => {
		reduceProductQuantity(item);
		// setQuantities((prevQuantities) => ({
		// 	...prevQuantities,
		// 	[item.id]: Math.max(
		// 		parseInt(prevQuantities[item.id], 10) - 1,
		// 		1
		// 	).toString(),
		// }));
	};

	return cartItems?.products?.length ? (
		cartItems.products.map((item, index) => (
			<div key={index}>
				<section className={style.clearfix}>
					<div>
						<Image
							src={
								item?.prescription_req === 0
									? item.image_urls && item.image_urls.length
										? item.image_urls[0]
										: '/images/no-image.png'
									: '/images/no-image.png'
							}
							height={60}
							width={60}
							alt={item.name}
						/>
					</div>
					<div style={{ width: '70%' }}>
						<span className={style.itemName}>{item.name}</span>
						<div>
							<Image
								src='/images/minus-sign-circle.svg'
								width={18}
								height={18}
								style={{ cursor: 'pointer' }}
								alt='remove'
								// onClick={() => reduceProductQuantity(item)
								onClick={() => handleDecreaseQuantity(item)}
							/>
							<span style={{ fontSize: '12px' }}>
								{' '}
								{item.quantity?.toString()}{' '}
							</span>
							{/* <span style={{ fontSize: '12px', display: 'inline-block' }}>
								{' '}
								<input
									type='number'
									className={`text-center ${style.quantityInput}`}
									value={
										isActive && !isCheckoutQuantity
											? item.quantity
											: quantities[item.id] || ''
									}
									onClick={() => setIsCheckoutQuantity(true)}
									onChange={(e) => handleChangeQuantity(e, item)}
									onBlur={(e) => handleBlurQuantity(e, item)}
									min='0'
									max={item.total_inventory}
									style={{
										padding: '0px',
										borderColor: '#e2156a',
										width: '52px',
										borderWidth: '1px',
										borderRadius: '10px',
									}}
								/>{' '}
							</span> */}
							<Image
								src='/images/plus-sign-circle.svg'
								width={18}
								height={18}
								style={
									item.quantity < 9999
										? { cursor: 'pointer' }
										: { opacity: '0.5', cursor: 'not-allowed' }
								}
								alt='add'
								// onClick={() => increaseProductQuantity(item)
								onClick={() => handleIncreaseQuantity(item)}
							/>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div
								style={{
									fontSize: '12px',
									fontWeight: '600',
									marginTop: '2px',
								}}>
								{Camelize(item?.uom)}
								{' at '}{' '}
								{process.env.NEXT_PUBLIC_COUNTRY_CODE === 'KE'
									? `KES ${
											item?.amount ||
											item?.pharma_product_uoms
												?.filter(
													(itm) =>
														itm.product_id === item.id &&
														itm.unit_of_measure === item.uom
												)
												?.map((itm) => itm.price.toLocaleString())[0]
									  }`
									: `RWF ${
											item?.amount ||
											item?.pharma_product_uoms
												?.filter(
													(itm) =>
														itm.product_id === item.id &&
														itm.unit_of_measure === item.uom
												)
												.map((itm) => itm.price.toLocaleString())[0]
									  }`}
							</div>
							<Image
								src='/images/delete.svg'
								width={18}
								height={18}
								style={{ cursor: 'pointer' }}
								alt='remove'
								onClick={() => removeProduct(item)}
							/>
						</div>
					</div>
				</section>
				<hr style={{ opacity: '.1' }} />
			</div>
		))
	) : (
		<></>
	);
};

export default CartItems;
