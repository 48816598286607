import React from 'react';
import styled from '@emotion/styled';
import Image from 'next/image';
import Link from 'next/link';
import Typography from '@/components/UI/Typography';
import Container from '../Container';

const fb = 'footer-bottom';

const Wrapper = styled('div')`
  padding: ${props => props.theme.spaces.medium1} 0;

    @media screen and (max-width: 768px) {
        order: 4;
        display: flex;
    }

  .footer-bottom {

    &__container {
        .row {
            @media screen and (max-width: 768px) {
                display: flex;
                flex-direction: column;
            }
        }
    }

    &__col {
      display: flex;
      align-items: flex-end;

      &.logo {
            @media screen and (max-width: 768px) {
                display: none;
            }

            img {
              object-fit: cover;
              width: auto;
              height: auto;
            }
        }

        &.ps {
            @media screen and (max-width: 768px) {
                padding-top: ${props => props.theme.spaces.small};
            }
        }
    }

    &__lists {
      display: flex;
      align-items: center;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__list {
      margin: 0 ${props => props.theme.spaces.mini}
              ${props => props.theme.spaces.little} 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  p {
    font-size: 12px;
  }

  a {
    color: ${props => props.theme.colors.main};
    text-decoration: none;
    font-size: 12px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const FooterBottom = () => {
    return (
        <Wrapper className='footer-bottom'>
            <Container className='footer-bottom__container'>
                <div className="row">
                    <div className={`col-sm-6`}>
                        <Typography variant={'p'} type={'p'}>
                            Ⓒ 2023 Kasha, Inc. All Rights Reserved
                        </Typography>
                    </div>
                </div>
            </Container>
        </Wrapper>
    );
};

export default FooterBottom;
