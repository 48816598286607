import React from 'react';
import styled from '@emotion/styled';
import SocialLinks from './SocialLinks';
import FooterMainKE from './FooterMain';
import FooterMainRW from './FooterMainRW';
import BackToTop from './BackToTop';
import FooterBottom from './FooterBottom';
import FooterBrand from './FooterBrand';
import SubscribeSection from './SubscribeSection';

const Wrapper = styled('footer')`
	position: relative;

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
	}

	.site-footer {
	}
`;

export default function Footer() {
	return (
		<Wrapper className={'site-footer'}>
			<SubscribeSection />
			{process.env.NEXT_PUBLIC_COUNTRY_CODE === 'KE' ? (
				<FooterMainKE />
			) : (
				<FooterMainRW />
			)}
			<FooterBottom />
		</Wrapper>
	);
}
