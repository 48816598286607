import React from 'react';
import styled from '@emotion/styled';
import Image from 'next/image';
import Typography from '@/components/UI/Typography';
import Container from '../Container';

const sl = 'social-links';

const Wrapper = styled('div')`


    @media screen and (max-width: 768px) {
        order: 3;
        display: flex;
    }

    .social-links {

        &__container {
            @media screen and (max-width: 768px) {
                max-width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }

        &__wrap {
            text-align: center;
            padding-top: ${props => props.theme.spaces.medium1};

            @media screen and (max-width: 768px) {
                padding-top: 0;
            }

            .typography__variant-h5 {
                margin-bottom: ${props => props.theme.spaces.mini};

                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }

        &__lists-wrap {
            padding: ${props => props.theme.spaces.small} 0;
            border-top: 1px solid ${props => props.theme.colors.grey2};
        }

        &__lists {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__list {
            margin-right: ${props => props.theme.spaces.large1};

            &:last-of-type {
                margin: 0;
            }
        }
    }
`;

const socialIcons = [
    {
        link: 'https://instagram.com/kashakenya',
        icon: '/images/social/icon-instagram.svg',
    },
    {
        link: 'https://www.facebook.com/Kasha-Kenya-102377730629654/',
        icon: '/images/social/icon-fb.svg',
    },
    {
        link: 'https://twitter.com/KashaGlobal',
        icon: '/images/social/icon-twitter.svg',
    },
];

const SocialLinks = () => {

    return (
        <Wrapper className='social-links'>
            <Container className='social-links__container'>
                <div className={`${sl}__wrap`}>
                    <Typography variant={'h5'} type={'h5'}>
                        Follow Kasha for news, Inspiration and offers
                    </Typography>

                    <div className={`${sl}__lists-wrap`}>
                        <ul className={`${sl}__lists`}>
                            {
                                (socialIcons || []).map((item, index) => {
                                    return (
                                        <li key={index} className={`${sl}__list`}>
                                            <a target="_blank" href={item?.link}>
                                                <Image
                                                    src={item?.icon}
                                                    width={44}
                                                    height={36}
                                                    alt={'Social icon'}
                                                    quality={80}
                                                />
                                            </a>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>

                </div>
            </Container>
        </Wrapper>
    );
};

export default SocialLinks;
