import styled from '@emotion/styled';
import React  from 'react';

const ContainerWrap = styled( 'div' )`
	width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 576px){
	    max-width: 540px;
	}

	@media (min-width: 768px){
		max-width: 720px;
	}

	@media (min-width: 992px){
		max-width: 960px;
	}

    @media (min-width: 1200px){
    	max-width: 1140px;
	}
	
	&.full-width {
	  max-width: 100vw;
	  padding: 0;
	  height: 100%;
	}
`

const Container = ( {children, className = ''} ) => {
    return (
        <ContainerWrap className={`container ${className}`}>
            {children}
        </ContainerWrap>
    );
};

export default Container;
