'use client';

import Image from 'next/image';
import './navbar.css';
import styled from '@emotion/styled';
import { getProductsBySearch } from '@/helpers/api-requests';
import { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import Typography from '../UI/Typography';
import { PreLoader } from '../preloader/PreLoader';

const Wrapper = styled('div')`
	.search-bar {
		&__wrapper {
			position: relative;
			padding-right: 0;
			padding-left: 0;
		}

		&__dropdown {
			z-index: 9000;
			position: absolute;
			left: 0;
			top: calc(100% + 5px);
			width: 100%;
			background: ${(c_props) => c_props.theme.colors.white};

			border: 1px solid ${(c_props) => c_props.theme.colors.grey2};
			margin-top: -1px;
			max-height: 210px;
			overflow-y: auto;

			&:empty {
				display: none;
			}
		}

		&__item {
			padding: ${(props) => props.theme.spaces.small};

			border-bottom: 1px solid ${(c_props) => c_props.theme.colors.grey2};

			a {
				text-decoration: none;
				color: ${(c_props) => c_props.theme.colors.main};

				span {
					color: inherit;
				}

				&:hover {
					color: ${(c_props) => c_props.theme.colors.magenta};
				}
			}

			&:last-of-type {
				border: none;
			}
		}
	}
`;

const SearchForm = () => {
	const [searchInputValue, setSearchInputValue] = useState('');
	const [dropdownList, setDropdownList] = useState([]);
	const [isFocused, setIsFocused] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const dropdownRef = useRef(null); //	For outside click

	//	For outside click
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setDropdownList([]);
				setIsFocused(false);
				setSearchInputValue('');
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);
	//	For outside click

	// Handler to set focus state to true
	const handleFocus = () => setIsFocused(true);

	// Handler to set focus state to false
	const handleBlur = () => {
		setIsFocused(false);
		// setDropdownList([]); // Clear the dropdown list when the input loses focus
	};

	const handleLinkClick = () => {
		setSearchInputValue('');
		setDropdownList([]);
	};

	const onSubmitHandler = async (event) => {
		event.preventDefault();
		// if (event.key === 'Enter') {
		setDropdownList(
			await getProductsBySearch(event.target?.searchQuery?.value)
		);
		// }
	};

	const handleOnBlur = async (event) => {
		event.preventDefault();
		handleBlur();
		// setSearchInputValue("");
	};

	const onChangeTextHandler = async (event) => {
		event.preventDefault();
		setSearchInputValue(event.target?.value);
		if (event.target?.value === '') {
			setDropdownList([]);
			setIsLoading(false); // Stop loading when input is cleared
		} else {
			setIsLoading(true); // Start loading when search begins
			const results = await getProductsBySearch(event.target?.value);
			setDropdownList(results);
			setIsLoading(false); // Stop loading when search is complete
		}
	};

	return (
		<div ref={dropdownRef}>
			<form
				className='d-flex align-items-center justify-content-center'
				onSubmit={onSubmitHandler}>
				<div
					className={`d-flex flex-row align-items-center justify-content-center border w-100 p-2 rounded-pill inputSection ${
						isFocused && 'border-success'
					}`}
					style={{
						fontSize: '0.7em',
						borderColor: isFocused ? 'pink' : 'initial',
					}}>
					<i>
						<Image
							src='/images/search.svg'
							alt='Search'
							width={12}
							height={12}
							className='mx-2'
						/>
					</i>
					<input
						className={`border-0 form-control w-100`}
						type='search'
						value={searchInputValue}
						name='search'
						placeholder='Search...'
						autoComplete='off'
						required
						onChange={onChangeTextHandler}
						onFocus={handleFocus}
					/>
					{isLoading && <PreLoader style={{ paddingBottom: 0 }} />}
				</div>
			</form>
			{isFocused && (
				<Wrapper>
					{dropdownList?.length > 0 && searchInputValue !== '' && (
						<div className={'search-bar__dropdown'}>
							{dropdownList.map((item, index) => {
								return (
									<div key={index} className={'search-bar__item'}>
										<a
											href={`/details?id=${item?.id}&country=${process.env.NEXT_PUBLIC_COUNTRY_CODE}`}>
											<Typography type={'span'} variant={'p'}>
												{item.name}
											</Typography>
										</a>
									</div>
								);
							})}
						</div>
					)}
					{dropdownList?.length < 1 && searchInputValue.length > 2 && (
						<div className={'search-bar__dropdown'}>
							<div className={'search-bar__item'}>
								<Typography type={'span'} variant={'p'}>
									Oops! Nothing found
								</Typography>
							</div>
						</div>
					)}
				</Wrapper>
			)}
		</div>
	);
};

export default SearchForm;
