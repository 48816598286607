import styled from '@emotion/styled';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import Typography from '../UI/Typography';
import Button from '../Buttons/Button';
import Container from '../Container';

const Wrapper = styled('div')`
	.footer-brand {
		&__container {
			padding: ${(props) => props.theme.spaces.small} 0;
			border-top: 1px solid ${(props) => props.theme.colors.grey2};
		}

		&__call-btn {
			height: 48px;
			width: 100%;
			max-width: 350px;
			border-radius: 0;
			background-color: ${(props) => props.theme.colors.grey4};
			padding: ${(props) => props.theme.spaces.mini}
				${(props) => props.theme.spaces.small};
		}

		&__logo-wrapper {
			display: flex;
			align-items: center;
		}

		&__text {
			margin-bottom: ${(props) => props.theme.spaces.small};
		}
	}
`;

const FooterBrand = () => {
	return (
		<Container>
			<Wrapper id='footer-brand' className='footer-brand'>
				<div className='text-center d-sm-block d-md-none'>
					<Button
						title={'Toll free call 0800721220 to order'}
						type='button'
						className='footer-brand__call-btn btn mt-4 mb-2'
					/>
					<Typography
						type={'p'}
						variant={'span'}
						className='footer-brand__text'>
						Order & easily pay over the phone with one of our <br />
						Kasha personal shoppers
					</Typography>
				</div>
				<div className='container footer-brand__container d-sm-block d-md-none'>
					<div className='row'>
						<div className='col-6'>
							<a className='footer-brand__logo-wrapper' href='/'>
								<Image
									src='/images/kasha_new.png'
									alt='Kasha logo'
									width={160}
									height={46}
								/>
							</a>
						</div>
						<div className='col-6 d-flex align-center align-self-end justify-content-end'>
							<Typography type={'p'} variant={'h6'} className='text-right mb-0'>
								<strong>It&apos;s time to care for you</strong>
							</Typography>
						</div>
					</div>
				</div>
			</Wrapper>
		</Container>
	);
};

export default FooterBrand;
