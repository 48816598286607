import styled from "@emotion/styled";

/**
 * Typography component
 *
 * @param type | the selected html tag
 * @param variant | the selected variant of styles that should be added to tag.
 * @param children | the node passed inside the Component
 * @param props | the default attribute of the Component
 * @param className | additional className
 * @return {*}
 * @constructor
 */
const Typography = ( {variant, type, children, className = '', ...props} ) => {

	// If the variant exists in variantsMapping, we use it.
	// Otherwise, use p tag instead.
	const Component = styled( (type && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span'].includes( type )) ? type : "div" )`
		${c_props => c_props.variant === 'h1' && c_props.theme.h1}		
		${c_props => c_props.variant === 'h2' && c_props.theme.h2}		
		${c_props => c_props.variant === 'h3' && c_props.theme.h3}		
		${c_props => c_props.variant === 'h4' && c_props.theme.h4}		
		${c_props => c_props.variant === 'h5' && c_props.theme.h5}		
		${c_props => c_props.variant === 'h6' && c_props.theme.h6}		
		${c_props => c_props.variant === 'subheader1' && c_props.theme.subheader1}		
		${c_props => c_props.variant === 'subheader2' && c_props.theme.subheader2}		
		${c_props => c_props.variant === 'subheader3' && c_props.theme.subheader3}		
		${c_props => c_props.variant === 'p' && c_props.theme.p}		
		${c_props => c_props.variant === 'body_large' && c_props.theme.body_large}		
		${c_props => c_props.variant === 'body_medium' && c_props.theme.body_medium}		
		${c_props => c_props.variant === 'body_normal' && c_props.theme.body_normal}		
		${c_props => c_props.variant === 'body_small' && c_props.theme.body_small}		
		${c_props => c_props.variant === 'body_mini' && c_props.theme.body_mini}		
	`;

	return (
		<Component
			className={`typography__variant-${variant} ${className}`}
            variant={variant}
            type={type}
			{...props}
		>
			{children}
		</Component>
	);
};

export default Typography;
