import React, { useState }  from 'react';
import styled               from '@emotion/styled';
import { validationFields } from '@/helpers/helper';
import Image from 'next/image';
import Container from '../Container';



const Wrapper = styled('div')`
    padding: ${props => props.theme.spaces.medium1} 0;

    input::placeholder {
        font-size: 1em; /* Adjust the default size as needed */
    }

    @media (max-width: 600px) {
        input::placeholder {
            font-size: 0.7em; /* Smaller font size for screens less than 600px wide */
        }
    }

    .newsletter {

        &__caption {
            margin-bottom: 0.5em;
        }

        &__subcaption {
            margin-bottom: 1rem;
        }

        &__form {
            display: flex;
        }

        &__form-control {

            &.email {
                width: 100%;
                max-width: 466px;
            }

            .form-input__error {
                width: 100%;
                margin-top: ${props => props.theme.spaces.little};
                font-size: 11px;
                color: ${props => props.theme.colors.red};
                text-align: left;
            }

            &.error {
                .form-input__field {
                    color: ${props => props.theme.colors.red};
                    border-color: ${props => props.theme.colors.red};
                }

                & ~ .submit.form-input {
                    .newsletter__form-submit {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                }
            }
        }

        &__form-field {
            border: solid 1px ${props => props.theme.colors.grey2};
            height: 44px;
            background-color: ${props => props.theme.colors.grey4};
            color: ${props => props.theme.colors.grey3};
            padding: 0 20px;
            width: 100%;
        }

        &__form-submit {
            ${props => props.theme.buttons.primary};
            height: 44px;
            font-size: 14px;
        }

        &__bottom-description {
            font-weight: normal;
        }
    }

`;

const Newsletter = () => {



    // 0 - form didn't send
    // 1 - form successfully send
    // 2 - form didn't successfully send
    const [ isFormSubmitted, setIsFormSubmitted ] = useState(0);
    const [ errorData, setErrorData ] = useState({
        email: {
            error: false,
            errorText: 'Please enter a valide email address'
        }
    });

    const onChangEmailField = (event) => {
        setErrorData( (prevState) => {
            return {
                ...prevState,
                email: validationFields('email', event.target.value)
            }
        } );
    }

    const onSubmitForm = async (event) => {
        event.preventDefault();

        /*setIsAlertVisible(true);

        const formData = new FormData();
        const option = {
            method: 'POST',
            body:   formData,
        };

        const result = await fetch(process.env.NEXT_PUBLIC_SUBMIT_FEEDBACK_FORM,
                                   option);
        const requestResult = await result.json();
        setIsFormSubmitted(requestResult.success === 'ok' ? 1 : 2);

        setTimeout(() => {
            setIsAlertVisible(false);
            closeStateHandler && closeStateHandler();
        }, 5000);*/
    };


    return (
        <Wrapper className="newsletter">
            <Container className="text-center">
                <div className="d-flex justify-content-center align-items-center">
                    <div className="col-md-9 col-lg-6 col-12">
                        {/* <form id="form-newsletter"
                              className={`newsletter__form mt-3 mb-3 justify-content-center`}
                              onSubmit={onSubmitForm}>
                            <div className='d-flex flex-row w-100 rounded-pill p-1' style={{backgroundColor: "white"}}>
                                <Image src={'/icons/email.svg'}
                                    className='mx-2'
                                    alt={'star image'}
                                    quality={90}
                                    width="0"
                                    height="0"
                                    style={{
                                        width:    'auto',
                                        maxWidth: '100%',
                                        height:   'auto',
                                    }}
                                />
                                <input placeholder='Enter your email for weekly deals' type='email' className='form-control w-100 border-0 rounded-start-pill' />
                                <input className=' form-control w-25 border-0 rounded-pill' style={{backgroundColor: "#E2156A", color: "white"}} type='submit' value={"Sign Up"} />
                            </div>
                        </form> */}

                    </div>
                </div>
            </Container>
        </Wrapper>
    );
};
export default Newsletter;
