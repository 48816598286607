'use client';

import style from './CartPop.module.scss';
import Image from 'next/image';
import { useState, useEffect } from 'react';
import Link from 'next/link';

const NotificationPop = ({ isOpen, toggleNotification }) => {
	const [isActive, setIsActive] = useState(isOpen);

	const userDetails =
		typeof window !== 'undefined' &&
		JSON.parse(localStorage.getItem('pharma_customer'));

	useEffect(() => {
		setIsActive(isOpen);
	}, [isOpen]);

	useEffect(() => {
		const shoppingCarts = document.querySelectorAll(`.${style.shoppingCart}`);
		shoppingCarts.forEach((cart, index) => {
			const delay = index * 50 + 'ms';
			cart.style.transitionDelay = delay;
		});
	}, []); // Empty dependency array ensures this effect runs only once on mount

	return (
		<>
			<div className={`${style.shoppingCart} ${isActive ? style.active : ''}`}>
				<div className={style.shoppingCartHeader}>
					<div>Notifications</div>
					<Image
						src='/images/cancel.svg'
						width={18}
						height={18}
						style={{ cursor: 'pointer' }}
						alt='Cancel'
						onClick={() => toggleNotification(false)}
					/>
				</div>
			</div>
		</>
	);
};

export default NotificationPop;
