import React      from 'react';
import styled     from '@emotion/styled';
import Image      from 'next/image';
import Typography from '@/components/UI/Typography';
import Container from '../Container';

const btt = 'back-to-top';

const Wrapper = styled('div')`
    background: ${props => props.theme.colors.pink};
    height: 44px;
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
        color: ${props => props.theme.colors.white};
        text-align: center;
    }

    .back-to-top {
        &__text-right {
            text-align: right;
        }
    }

`;

const goToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
};

const BackToTop = () => {
    return (
        <Wrapper onClick={goToTop}>
            <Container>
                <div className="row">
                    <div className={`col-2 ${btt}__text-left`}>
                        <Image
                            src={'/icons/icon-triangle.svg'}
                            width={20}
                            height={16}
                            alt={'icon-triange'}
                            title={'icon-triange'}
                            quality={80}
                        />
                    </div>
                    <div className="col-8">
                        <Typography variant={'p'} type={'p'}>
                            Return to top of the page
                        </Typography>
                    </div>
                    <div className={`col-2 ${btt}__text-right`}>
                        <Image
                            src={'/icons/icon-triangle.svg'}
                            width={20}
                            height={16}
                            alt={'icon-triange'}
                            title={'icon-triange'}
                            quality={80}
                        />
                    </div>
                </div>
            </Container>
        </Wrapper>
    );
};

export default BackToTop;
