import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import {
	addNewProductToDB,
	editExistingProduct,
	removeExistingProduct,
} from '../lib/features/cartItemsSlice';

const useCart = () => {
	const dispatch = useAppDispatch();
	const cartItems = useAppSelector((state) => state.cartItems.cartItems);

	const addToCart = async (product, uom) => {
		await dispatch(addNewProductToDB(product, uom));
		// gtag('event', 'user_interation', {
		// 	event_category: 'cart_related_clicks',
		// 	event_label: 'Add to Cart clicked',
		// });
	};

	const reduceProductQuantity = (product) => {
		const productInfo = cartItems?.products.find(
			(item) => item.id === product.id
		);
		if (productInfo) {
			dispatch(
				editExistingProduct(
					{
						...productInfo,
						quantity: parseInt(productInfo.quantity, 0) - 1,
						cartId: cartItems.id,
					},
					'decrease'
				)
			);
			// gtag('event', 'user_interation', {
			// 	event_category: 'cart_related_clicks',
			// 	event_label: `Item with this ID ${productInfo.id} Quantity is decreased`,
			// });
		}
	};

	const increaseProductQuantity = (product) => {
		const productInfo = cartItems?.products.find(
			(item) => item.id === product.id
		);
		if (productInfo && productInfo.quantity > 0) {
			dispatch(
				editExistingProduct(
					{
						...productInfo,
						quantity: parseInt(productInfo.quantity, 0) + 1,
						cartId: cartItems.id,
					},
					'increase'
				)
			);
			// gtag('event', 'user_interation', {
			// 	event_category: 'cart_related_clicks',
			// 	event_label: `Item with this ID ${productInfo.id} Quantity is increased`,
			// });
		}
	};

	const manualProductQuantity = (product, qty) => {
		const productInfo = cartItems?.products.find(
			(item) => item.id === product?.id
		);

		if (productInfo && productInfo.quantity > 0) {
			dispatch(
				editExistingProduct(
					{
						...productInfo,
						quantity: parseInt(qty, 0),
						cartId: cartItems.id,
					},
					'manual'
				)
			);
			// gtag('event', 'user_interation', {
			// 	event_category: 'cart_related_clicks',
			// 	event_label: `Item with this ID ${productInfo.id} Quantity set manually`,
			// });
		}
	};

	const removeProduct = (product) => {
		const productInfo = cartItems.products.find(
			(item) => item.id === product.id
		);
		if (productInfo) {
			dispatch(removeExistingProduct({ ...productInfo, cartId: cartItems.id }));
			// gtag('event', 'user_interation', {
			// 	event_category: 'cart_related_clicks',
			// 	event_label: `Item with this ID ${productInfo.id} was removed`,
			// });
		}
	};
	return {
		addToCart,
		reduceProductQuantity,
		increaseProductQuantity,
		manualProductQuantity,
		removeProduct,
	};
};

export default useCart;
