'use client';

import Image from 'next/image';
import style from './Navbar.module.css';
import Link from 'next/link';
import { useState, useEffect, useRef } from 'react';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import Pusher from 'pusher-js';
import Cookies from 'js-cookie';
import { useAppSelector } from '@/lib/hooks';
import { useDispatch } from 'react-redux';
import { fetchCartData } from '@/lib/features/cartItemsSlice';
import Button from '../Buttons/Button';
import { getProductsBySearch } from '@/helpers/api-requests';
import styled from '@emotion/styled';
import Typography from '../UI/Typography';
import SearchForm from './SearchForm';

const Wrapper = styled('div')`
	.search-bar {
		&__wrapper {
			position: relative;
			padding-right: 0;
			padding-left: 0;
		}

		&__dropdown {
			z-index: 9000;
			position: absolute;
			left: 0;
			top: calc(100% + 5px);
			width: 100%;
			background: ${(c_props) => c_props.theme.colors.white};

			border: 1px solid ${(c_props) => c_props.theme.colors.grey2};
			margin-top: -1px;
			max-height: 210px;
			overflow-y: auto;

			&:empty {
				display: none;
			}
		}

		&__item {
			padding: ${(props) => props.theme.spaces.small};

			border-bottom: 1px solid ${(c_props) => c_props.theme.colors.grey2};

			a {
				text-decoration: none;
				color: ${(c_props) => c_props.theme.colors.main};

				span {
					color: inherit;
				}

				&:hover {
					color: ${(c_props) => c_props.theme.colors.magenta};
				}
			}

			&:last-of-type {
				border: none;
			}
		}
	}
`;

export function NavBar({
	isOpen,
	toggleCart,
	toggleNotification,
	handleBackToHome,
}) {
	const isActiveLink = usePathname();
	const router = useRouter();
	const searchParams = useSearchParams();

	const overlayRef = useRef(null);
	const [storedToken, setStoredToken] = useState('');
	const cookieToken = Cookies.get('token');
	const ncd_active = Cookies.get('ncd_active');
	const dispatch = useDispatch();
	const [screenWidth, setScreenWidth] = useState(
		typeof window !== 'undefined' ? window.innerWidth : null
	);

	const [retrievedUserData, setRetrievedUserData] = useState('');
	const [navbarItems, setNavbarItems] = useState([]);
	const [notificationCountKE, setNotificationCountKE] = useState(0);
	const [notificationCountRW, setNotificationCountRW] = useState(0);
	const [notificationMessage, setNotificationMessage] = useState('');
	const [isNotificationPopupOpen, setIsNotificationPopupOpen] = useState(false);
	const [isAvatarPopupOpen, setIsAvatarPopupOpen] = useState(false);
	const [isAvatarMobilePopupOpen, setIsAvatarMobilePopupOpen] = useState(false);

	const avatarDropdownRef = useRef(null); //	Avatar dropdown reference state
	const avatarMobileDropdownRef = useRef(null); //	Avatar dropdown reference state

	const [searchInputValue, setSearchInputValue] = useState('');
	const [dropdownList, setDropdownList] = useState([]);

	const cartItems = useAppSelector((state) => state.cartItems.cartItems);

	const [isClient, setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	const openMenu = () => {
		if (overlayRef.current) {
			overlayRef.current.classList.add(style.overlay__active);
		}
	};

	const closeMenu = () => {
		if (overlayRef.current) {
			overlayRef.current.classList.remove(style.overlay__active);
		}
	};

	const handleClick = () => {
		toggleCart();
	};

	useEffect(() => {
		dispatch(fetchCartData());
	}, []);

	useEffect(() => {
		const doc = document;
		const menuOpen = doc.querySelector('.menu');
		const menuClose = doc.querySelector('.close');
		const hamburger = doc.querySelector('.hamburger');

		if (menuOpen) {
			menuOpen.addEventListener('click', openMenu);
			hamburger.addEventListener('click', () => {
				hamburger.classList.toggle(`${style.active}`);
				// Toggle your overlay or perform any other actions here
			});
		}

		if (menuClose) {
			menuClose.addEventListener('click', closeMenu);
		}

		return () => {
			// Clean up event listeners if component is unmounted
			if (menuOpen) {
				menuOpen.removeEventListener('click', openMenu);
				hamburger.addEventListener('click', () => {
					hamburger.classList.toggle(`${style.active}`);
					// Toggle your overlay or perform any other actions here
				});
			}

			if (menuClose) {
				menuClose.removeEventListener('click', closeMenu);
			}
		};
	}, []);

	useEffect(() => {
		// const cachedData = localStorage.getItem('navbarItems');

		const storedCustomer =
			typeof window !== 'undefined'
				? localStorage.getItem('pharma_customer')
				: null;
		const token = storedCustomer ? JSON.parse(storedCustomer).token : null;
		const userId = storedCustomer ? JSON.parse(storedCustomer).id : '';
		setStoredToken(token);

		// if (cachedData) {
		// 	setNavbarItems(JSON.parse(cachedData));
		// } else {
		const apiUrl = `${process.env.NEXT_PUBLIC_BACKEND_URL}${process.env.NEXT_PUBLIC_PHARMA_NAVBAR_MENU_API}?name=${process.env.NEXT_PUBLIC_NAVBAR_ITEMS_NAME}&country=${process.env.NEXT_PUBLIC_COUNTRY_CODE}&user_id=${userId}`;

		fetch(apiUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((result) => {
				// Extracting the locations array from the response object
				const locationsArray = result && result.data ? result.data : [];

				if (Array.isArray(locationsArray)) {
					setNavbarItems(locationsArray[0]?.items?.menuData);

					// Cache the fetched data in localStorage
					// localStorage.setItem(
					// 	'navbarItems',
					// 	JSON.stringify(locationsArray[0]?.items?.menuData)
					// );
				} else {
					console.error('Invalid locations data:', result);
				}
			})
			.catch((error) => {
				console.error('Fetch error:', error);
			});
		// }
	}, []);

	useEffect(() => {
		fetchProfileData();
	}, []);

	const fetchProfileData = () => {
		const userData = JSON.parse(localStorage.getItem('pharma_customer'));

		const apiUrl = `${process.env.NEXT_PUBLIC_CMS_URL}${process.env.NEXT_PUBLIC_PROFILE_API_PATH}`;

		if (userData?.token) {
			fetch(apiUrl, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userData?.token}`,
				},
			})
				.then((response) => response.json())
				.then((result) => {
					const profileData = result?.data ?? [];
					setRetrievedUserData(profileData);
					//   setUserToken(userData?.token);
				})
				.catch((error) => {
					console.error('Fetch error:', error);
				});
		}
	};

	// Pusher Test
	// useEffect(() => {
	// 	const ownerid = JSON.parse(localStorage.getItem('pharma_customer'));

	// 	if (process.env.NEXT_PUBLIC_COUNTRY_CODE === 'KE') {
	// 		const storedNotificationCountKE =
	// 			localStorage.getItem('notifications_KE');
	// 		setNotificationCountKE(storedNotificationCountKE);
	// 	} else {
	// 		const storedNotificationCountRW =
	// 			localStorage.getItem('notifications_RW');
	// 		setNotificationCountRW(storedNotificationCountRW);
	// 	}

	// 	// if (storedNotificationCountKE) {
	// 	// 	setNotificationCountKE(parseInt(storedNotificationCountKE, 10));
	// 	// }
	// 	// if (storedNotificationCountRW) {
	// 	// 	setNotificationCountRW(parseInt(storedNotificationCountRW, 10));
	// 	// }

	// 	// Initialize Pusher with your credentials
	// 	const pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_APP_KEY, {
	// 		cluster: 'mt1',
	// 	});

	// 	const channel = pusher.subscribe('kasha-cms-notification-development');

	// 	// Below Cluster Name is bind with above channel name
	// 	channel.bind('send-business-verified-pusher-notification', (data) => {
	// 		// Handle new order event, update state, show notification, etc.

	// 		if (data.user_id === ownerid?.id) {
	// 			setNotificationCountKE((prevCount) => prevCount + 1);
	// 			setNotificationCountRW((prevCount) => prevCount + 1);
	// 			setNotificationMessage(data.message);

	// 			localStorage.setItem('notifications_KE', notificationCountKE + 1);
	// 			localStorage.setItem('notifications_RW', notificationCountRW + 1);
	// 			localStorage.setItem('notifications_message', data.message);
	// 		}
	// 	});

	// 	return () => {
	// 		// Unsubscribe from the channel when component unmounts
	// 		channel.unbind_all();
	// 		channel.unsubscribe();
	// 	};
	// }, [
	// 	notificationCountKE,
	// 	notificationCountRW,
	// 	process.env.NEXT_PUBLIC_COUNTRY_CODE,
	// ]);

	const getCartTotal = () => {
		return cartItems.products
			.reduce((total, currentItem) => total + currentItem.quantity, 0)
			.toString();
	};

	const toggleNotificationPopup = () => {
		setIsNotificationPopupOpen(!isNotificationPopupOpen);
		// toggleNotification();
	};

	const handleNotificationClose = () => {
		setIsNotificationPopupOpen(false);
		localStorage.removeItem('notifications_message');
		localStorage.removeItem('notifications_KE');
		localStorage.removeItem('notifications_RW');
		setNotificationMessage('');
		setNotificationCountKE(0);
		setNotificationCountRW(0);
	};

	const onChangeTextHandler = async (event) => {
		event.preventDefault();
		setSearchInputValue(event.target?.value);
		if (event.target?.value === '') {
			setDropdownList([]);
		} else {
			setDropdownList(await getProductsBySearch(event.target?.value));
		}
	};

	const handleOnBlur = async (event) => {
		event.preventDefault();
		setDropdownList(await getProductsBySearch(''));
		event.target.value = '';
		setDropdownList([]);
	};

	const onSubmitHandler = async (event) => {
		event.preventDefault();
		// if (event.key === 'Enter') {
		setDropdownList(
			await getProductsBySearch(event.target?.searchQuery?.value)
		);
		// }
	};

	const goToProduct = () => {
		setDropdownList([]);
	};

	const handleAvatarClose = () => {
		setIsAvatarPopupOpen(false);
	};

	const handleAvatarMobileClose = () => {
		setIsAvatarMobilePopupOpen(false);
	};

	function handleAvatarClick() {
		setIsAvatarPopupOpen(true);
		setIsAvatarMobilePopupOpen(false);
	}

	function handleAvatarMobileClick() {
		setIsAvatarMobilePopupOpen(true);
		setIsAvatarPopupOpen(false);
	}

	function handleLogOut() {
		localStorage.removeItem('pharma_customer');
		localStorage.removeItem('after_verification');
		Cookies.remove('token');
		window.location.href = '/';
	}

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	// On outside click
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				avatarDropdownRef.current &&
				!avatarDropdownRef.current.contains(event.target)
			) {
				handleAvatarClose();
			}
		};

		if (isAvatarPopupOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isAvatarPopupOpen, handleAvatarClose]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				avatarMobileDropdownRef.current &&
				!avatarMobileDropdownRef.current.contains(event.target)
			) {
				handleAvatarMobileClose();
			}
		};

		if (isAvatarMobilePopupOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isAvatarMobilePopupOpen, handleAvatarMobileClose]);
	// On outside click

	return (
		<>
			{isClient ? (
				<div>
					<div
						className={style.header}
						style={
							isActiveLink === '/details' ||
							isActiveLink === '/checkout' ||
							isActiveLink.includes('/articles') ||
							isActiveLink.includes('/category') ||
							isActiveLink.includes('/ncd')
								? { height: '3.9rem' }
								: null
						}>
						{isActiveLink === '/' ? (
							<Link className={style.logo} href='#' onClick={handleBackToHome}>
								<Image
									src='/images/kasha_new.png'
									alt='logo'
									width={100}
									height={28}
								/>
							</Link>
						) : (
							<Link className={style.logo} href='/'>
								<Image
									src='/images/kasha_new.png'
									alt='logo'
									width={100}
									height={28}
								/>
							</Link>
						)}

						<nav style={{ fontSize: '12px', width: '100%' }}>
							<ul className={style.nav__links}>
								{navbarItems &&
									navbarItems.map((item, index) =>
										// item.itemName === 'Orders' ? null :
										(item.itemName === 'Profile & Settings' && !cookieToken) ||
										(item.itemName === 'Orders' && !cookieToken) ||
										(item.itemName === 'NCD' && !cookieToken) ? null : (
											<li className={style.navListItem} key={index}>
												<Link href={item.href} replace>
													<div
														className={`${style.group} ${
															isActiveLink === item.href ||
															isActiveLink === `${item.href}/edit`
																? style.active
																: ''
														}`}>
														{item.itemName === 'Orders' &&
														!cookieToken ? null : item.itemName ===
																'Profile & Settings' && !cookieToken ? null : (
															<Image
																src={`/images/${item.icon}.svg`}
																alt={item.icon}
																width={16}
																height={16}
															/>
														)}
														{item.itemName}
													</div>
												</Link>
											</li>
										)
									)}
							</ul>
						</nav>

						<nav style={{ width: '80%' }}>
							<ul className={style.nav__links}>
								<li className='d-none d-sm-block' style={{ width: 300 }}>
									<SearchForm />
								</li>
								<li className={style.navListItem}>
									<Link href='#'>
										{/* <div className={style.'group'> */}
										<div
											className={style.cartIcon}
											onClick={() => handleClick()}
											onKeyDown={() => handleClick()}>
											<Image
												src='/images/shopping-cart.svg'
												alt='Cart Icon'
												width={16}
												height={16}
											/>
											{cartItems?.products?.length > 0 && (
												<span className={style.cartBadge}>
													{cartItems?.products?.length
														? getCartTotal() > 99
															? '99+'
															: getCartTotal()
														: ''}
												</span>
											)}
										</div>
										{/* </div> */}
									</Link>
								</li>
								{/* <li className={style.navListItem}> */}
								{/* <Link href='#'> */}
								{/* <div className={style.'group'> */}
								{/* <Image
									src='/images/favourite.svg'
									alt='Favourite Icon'
									width={16}
									height={16}
								/> */}
								{/* </div> */}
								{/* </Link> */}
								{/* </li> */}
								{storedToken &&
									// <li className={style.navListItem}>
									// 	<Link href='#'>
									// 		{/* <div className={style.'group'> */}
									// 		<div
									// 			className={style.cartIcon}
									// 			onClick={() => toggleNotificationPopup()}
									// 			onKeyDown={() => toggleNotificationPopup()}>
									// 			<Image
									// 				src='/images/notification.svg'
									// 				alt='Notification Icon'
									// 				width={16}
									// 				height={16}
									// 			/>
									// 			{process.env.NEXT_PUBLIC_COUNTRY_CODE === 'KE' &&
									// 				notificationCountKE > 0 && (
									// 					<span className={style.cartBadge}>
									// 						{notificationCountKE > 99
									// 							? '99+'
									// 							: notificationCountKE}
									// 					</span>
									// 				)}
									// 			{process.env.NEXT_PUBLIC_COUNTRY_CODE === 'RW' &&
									// 				notificationCountRW > 0 && (
									// 					<span className={style.cartBadge}>
									// 						{notificationCountRW > 99
									// 							? '99+'
									// 							: notificationCountRW}
									// 					</span>
									// 				)}
									// 		</div>
									// 		{/* </div> */}
									// 	</Link>
									// </li>
									null}
								{isNotificationPopupOpen &&
									// <div className={style.notificationPopupContainer}>
									// 	<div
									// 		style={{
									// 			padding: '1rem',
									// 			display: 'flex',
									// 			alignItems: 'center',
									// 			justifyContent: 'space-between',
									// 			fontSize: '12px',
									// 			fontWeight: 'bold',
									// 				fontFamily: `'Nunito Sans', sans-serif`,
									// 		}}>
									// 		Notifications
									// 		<Image
									// 			src='/images/cancel.svg'
									// 			width={18}
									// 			height={18}
									// 			style={{ cursor: 'pointer' }}
									// 			alt='Cancel'
									// 			onClick={() => handleNotificationClose()}
									// 		/>
									// 	</div>
									// 	<div className={style.notificationPopupContent}>
									// 		<div
									// 			style={{
									// 				textAlign: 'center',
									// 				fontSize: '10px',
									// 					fontFamily: `'Nunito Sans', sans-serif`,
									// 			}}>
									// 			{notificationMessage !== ''
									// 				? notificationMessage
									// 				: 'There are no notifications.'}
									// 		</div>
									// 	</div>
									// </div>
									null}
								{cookieToken ? (
									<li
										className={style.navListItem}
										onClick={handleAvatarClick}
										onKeyDown={handleAvatarClick}>
										<div
											className={style.group}
											style={{ width: 'max-content', cursor: 'pointer' }}>
											<Image
												src={
													retrievedUserData &&
													retrievedUserData.owner?.profile_photo_url
														? retrievedUserData.owner?.profile_photo_url
														: '/images/avatar.png'
												}
												alt='Avatar Icon'
												width={30}
												height={30}
												style={{ borderRadius: '50%' }}
											/>

											<span
												style={{
													fontSize: '12px',
													color: '#1f2937',
													fontWeight: '500',
													fontFamily: `'Nunito Sans', sans-serif`,
												}}>
												{retrievedUserData.owner?.fullname}
											</span>
										</div>
									</li>
								) : (
									<div>
										<Link
											href='/login'
											className={style.buttonLink}
											style={{ fontWeight: 600, color: '#ffffff' }}>
											Log In/Register
										</Link>
									</div>
								)}
							</ul>
						</nav>

						{screenWidth > 1024 && isAvatarPopupOpen && (
							<div
								className={style.avatarPopupContainer}
								ref={avatarDropdownRef}>
								{/* Popup content */}
								<div
									style={{
										padding: '1rem',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										fontSize: '12px',
										fontWeight: 'bold',
										fontFamily: `'Nunito Sans', sans-serif`,
									}}>
									<div>{retrievedUserData.owner?.fullname}</div>

									<Image
										src='/images/cancel.svg'
										width={18}
										height={18}
										style={{ cursor: 'pointer' }}
										alt='Cancel'
										onClick={() => handleAvatarClose()}
									/>
								</div>
								<div>
									{/* Content goes here */}
									<div
										style={{
											textAlign: 'center',
											fontSize: '10px',
											fontFamily: `'Nunito Sans', sans-serif`,
										}}>
										<div style={{ paddingBottom: '12px' }}>
											<Link href='/profile' style={{ textDecoration: 'none' }}>
												Profile & Settings
											</Link>
										</div>
										<div style={{ paddingBottom: '12px', paddingTop: '6px' }}>
											{/* <Link
										href='/login'
										style={{ textDecoration: 'none' }}
										onClick={handleLogOut}>
										Sign Out
									</Link> */}
											<button
												onClick={handleLogOut}
												style={{
													border: 'none',
													background: 'none',
													color: 'blue',
													fontSize: '10px',
													fontWeight: 'normal',
													margin: '0px 0px 12px',
													height: '0px',
												}}>
												Sign Out
											</button>
										</div>
									</div>
								</div>
							</div>
						)}

						{/* <Link className={style.'cta' href='#'}>
					Contact
				</Link> */}

						{/* Cart in Mobile View */}

						<div
							className={`${style.menu}`}
							style={{ padding: '12px 10px 12px 10px', cursor: 'pointer' }}>
							<Link href='/checkout'>
								<div className={style.cartIcon}>
									<Image
										src='/images/shopping-cart.svg'
										alt='Cart Icon'
										width={16}
										height={16}
									/>
									{cartItems?.products?.length > 0 && (
										<span className={style.cartBadge}>
											{cartItems?.products?.length
												? getCartTotal() > 99
													? '99+'
													: getCartTotal()
												: ''}
										</span>
									)}
								</div>
							</Link>
						</div>

						{/* Favourites in Mobile View */}

						{/* <div
					className={`${style.menu}`}
					style={{ padding: '12px 10px 12px 10px', cursor: 'pointer' }}>
					<Image
						src='/images/favourite.svg'
						alt='Favourite Icon'
						width={16}
						height={16}
					/>
				</div> */}

						{/* Notification in Mobile View */}

						{storedToken &&
							// <div
							// 	className={`${style.menu}`}
							// 	style={{ padding: '12px 10px 12px 10px', cursor: 'pointer' }}
							// 	onClick={toggleNotificationPopup}
							// 	onKeyDown={toggleNotificationPopup}>
							// 	<div className={style.cartIcon}>
							// 		<Image
							// 			src='/images/notification.svg'
							// 			alt='Notification Icon'
							// 			width={16}
							// 			height={16}
							// 		/>
							// 		{process.env.NEXT_PUBLIC_COUNTRY_CODE === 'KE' &&
							// 			notificationCountKE > 0 && (
							// 				<span className={style.cartBadge}>
							// 					{notificationCountKE > 99 ? '99+' : notificationCountKE}
							// 				</span>
							// 			)}
							// 		{process.env.NEXT_PUBLIC_COUNTRY_CODE === 'RW' &&
							// 			notificationCountRW > 0 && (
							// 				<span className={style.cartBadge}>
							// 					{notificationCountRW > 99 ? '99+' : notificationCountRW}
							// 				</span>
							// 			)}
							// 	</div>
							// 	{isNotificationPopupOpen && (
							// 		<div className={style.notificationPopupContainer}>
							// 			{/* Popup content */}
							// 			<div className={style.notificationPopupContent}>
							// 				{/* Content goes here */}I am testing here 2
							// 			</div>
							// 		</div>
							// 	)}
							// </div>
							null}

						{/* User Avatar in Mobile View */}

						{cookieToken ? (
							<div
								className={`${style.menu}`}
								style={{ padding: '15px 18px 15px 8px' }}
								onClick={handleAvatarMobileClick}
								onKeyDown={handleAvatarMobileClick}>
								<Image
									src={
										retrievedUserData &&
										retrievedUserData.owner?.profile_photo_url
											? retrievedUserData.owner?.profile_photo_url
											: '/images/avatar.png'
									}
									alt='Avatar Icon'
									width={30}
									height={30}
									style={{ borderRadius: '50%', cursor: 'pointer' }}
								/>
							</div>
						) : (
							<div
								className={`${style.menu}`}
								style={{ padding: '15px 18px 15px 8px' }}>
								<Link href='/login' className={style.buttonLink}>
									Log In/Register
								</Link>
							</div>
						)}

						{screenWidth < 1024 && isAvatarMobilePopupOpen && (
							<div
								className={style.avatarMobilePopupContainer}
								ref={avatarMobileDropdownRef}>
								{/* Popup content */}
								<div
									style={{
										padding: '1rem',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										fontSize: '12px',
										fontWeight: 'bold',
										fontFamily: `'Nunito Sans', sans-serif`,
									}}>
									<div>{retrievedUserData.owner?.fullname}</div>

									<Image
										src='/images/cancel.svg'
										width={18}
										height={18}
										style={{ cursor: 'pointer' }}
										alt='Cancel'
										onClick={() => handleAvatarMobileClose()}
									/>
								</div>
								<div
								// className={style.notificationPopupContent}
								>
									{/* Content goes here */}
									<div
										style={{
											textAlign: 'center',
											fontSize: '10px',
											fontFamily: `'Nunito Sans', sans-serif`,
										}}>
										<div style={{ paddingBottom: '12px' }}>
											<Link href='/profile' style={{ textDecoration: 'none' }}>
												Profile & Settings
											</Link>
										</div>
										<div style={{ paddingBottom: '12px' }}>
											{/* <Link
										href='/login'
										style={{ textDecoration: 'none' }}
										onClick={handleLogOut}>
										Sign Out
									</Link> */}
											<button
												onClick={handleLogOut}
												style={{
													border: 'none',
													background: 'none',
													color: 'blue',
													fontSize: '10px',
													fontWeight: 'normal',
													margin: '0px 0px 12px',
													height: '0px',
												}}>
												Sign Out
											</button>
										</div>
									</div>
								</div>
							</div>
						)}

						{/* <div className={`${style.menu} ${style.cta}`} onClick={openMenu}>
					Menu
				</div> */}
						<div
							className={style.hamburger}
							onClick={openMenu}
							onKeyDown={openMenu}>
							<div className={style.bar}></div>
							<div className={style.bar}></div>
							<div className={style.bar}></div>
						</div>
					</div>

					<div
						className={`${style.searchContainer} p-2`}
						style={{ backgroundColor: '#ffffff' }}>
						<div className='d-block d-md-none'>
							<SearchForm />
						</div>
						{isNotificationPopupOpen && (
							<div className={style.notificationPopupContainer}>
								{/* Popup content */}
								<div className={style.notificationPopupContent}>
									{/* Content goes here */}I am testing here 3
								</div>
							</div>
						)}
					</div>

					<div className={style.overlay} ref={overlayRef}>
						<Link className={style.close} href='#' onClick={closeMenu}>
							&times;
						</Link>

						<div className={style.overlay__content}>
							<Link href='/'>Home</Link>
							{cookieToken && <Link href='/orders'>Orders</Link>}
							<Link href='/shop'>Products</Link>
							<Link href='/about'>About Us</Link>
							{cookieToken && ncd_active === '1' && (
								<Link href='/ncd'>NCD</Link>
							)}
							{cookieToken && <Link href='/profile'>Profile & Settings</Link>}
						</div>
					</div>
				</div>
			) : null}
		</>
	);
}
