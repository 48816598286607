import React      from 'react';
import styled     from '@emotion/styled';
import Newsletter from '../Newsletter/Newsletter';
import Container from '../Container';

const Wrapper = styled('div')`
    background: #E2156A;
    height: 100px;
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
        color: ${props => props.theme.colors.white};
        text-align: center;
    }

    .back-to-top {
        &__text-right {
            text-align: right;
        }
    }

`;


const SubscribeSection = () => {
    return (
        <Wrapper >
            <Container>
                <div className="row">
                    <div className="col-12">
                        <Newsletter/>
                    </div>
                    
                </div>
            </Container>
        </Wrapper>
    );
};

export default SubscribeSection;
