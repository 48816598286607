import styled from '@emotion/styled';
import React  from "react";
import Image  from "next/image";

const Btn = styled.button`
    
    white-space: nowrap;
    
	&.btn-primary {
		${props => props.theme.buttons.primary}
	}
	
	&.disabled {
		pointer-events: none;
		opacity: 0.6;		
	} 
`

const Button = ( {title, type = 'primary', className = '', image, ...rest} ) => {

	if ( title === '' ) return '';

	return (
		<Btn className={`btn btn-${type} ${className}`} {...rest}>
			{
				(image && typeof image === 'string') &&
				<Image width={22} height={15} alt={'icon search'} title={'icon search'} src={image}/>
			}
            {
				(image && React.isValidElement(image)) && image
			}
			{title}
		</Btn>
	);
}

export default Button;
