import React from 'react'

/**
 * Adding leading Zero to number
 * @param num
 * @param places
 * @return {string}
 *
 * examples:
 *      console.log(zeroPad(5, 2)); // "05"
 console.log(zeroPad(5, 4)); // "0005"
 console.log(zeroPad(5, 6)); // "000005"
 console.log(zeroPad(1234, 2)); // "1234"
 */
export const zeroPad = (num, places) => String(num).padStart(places, '0')

/**
 *
 *  Secure Hash Algorithm (SHA1)
 *  http://www.webtoolkit.info/
 *
 **/
export function SHA1 (msg) {

  function rotate_left (n, s) {
    var t4 = (n << s) | (n >>> (32 - s))
    return t4
  }

  function lsb_hex (val) {
    var str = ''
    var i
    var vh
    var vl

    for (i = 0; i <= 6; i += 2) {
      vh = (val >>> (i * 4 + 4)) & 0x0f
      vl = (val >>> (i * 4)) & 0x0f
      str += vh.toString(16) + vl.toString(16)
    }

    return str
  }

  function cvt_hex (val) {
    var str = ''
    var i
    var v

    for (i = 7; i >= 0; i--) {
      v = (val >>> (i * 4)) & 0x0f
      str += v.toString(16)
    }

    return str
  }

  function Utf8Encode (string) {
    string = string.replace(/\r\n/g, '\n')
    var utftext = ''

    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n)

      if (c < 128) {
        utftext += String.fromCharCode(c)
      } else if ((c > 127) && (c < 2048)) {
        utftext += String.fromCharCode((c >> 6) | 192)
        utftext += String.fromCharCode((c & 63) | 128)
      } else {
        utftext += String.fromCharCode((c >> 12) | 224)
        utftext += String.fromCharCode(((c >> 6) & 63) | 128)
        utftext += String.fromCharCode((c & 63) | 128)
      }
    }

    return utftext
  }

  var blockstart
  var i, j
  var W = new Array(80)
  var H0 = 0x67452301
  var H1 = 0xEFCDAB89
  var H2 = 0x98BADCFE
  var H3 = 0x10325476
  var H4 = 0xC3D2E1F0
  var A, B, C, D, E
  var temp

  msg = Utf8Encode(msg)

  var msg_len = msg.length
  var word_array = new Array()

  for (i = 0; i < msg_len - 3; i += 4) {
    j = msg.charCodeAt(i) << 24 | msg.charCodeAt(i + 1) << 16 |
      msg.charCodeAt(i + 2) << 8 | msg.charCodeAt(i + 3)
    word_array.push(j)
  }

  switch (msg_len % 4) {
    case 0:
      i = 0x080000000
      break

    case 1:
      i = msg.charCodeAt(msg_len - 1) << 24 | 0x0800000
      break

    case 2:
      i = msg.charCodeAt(msg_len - 2) << 24 |
        msg.charCodeAt(msg_len - 1) << 16 | 0x08000
      break

    case 3:
      i = msg.charCodeAt(msg_len - 3) << 24 |
        msg.charCodeAt(msg_len - 2) << 16 |
        msg.charCodeAt(msg_len - 1) << 8 | 0x80
      break
  }

  word_array.push(i)

  while ((word_array.length % 16) !== 14) word_array.push(0)

  word_array.push(msg_len >>> 29)
  word_array.push((msg_len << 3) & 0x0ffffffff)

  for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {

    for (i = 0; i < 16; i++) W[i] = word_array[blockstart + i]

    for (i = 16; i <= 79; i++) W[i] = rotate_left(
      W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1)

    A = H0
    B = H1
    C = H2
    D = H3
    E = H4

    for (i = 0; i <= 19; i++) {
      temp = (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] +
        0x5A827999) & 0x0ffffffff
      E = D
      D = C
      C = rotate_left(B, 30)
      B = A
      A = temp
    }

    for (i = 20; i <= 39; i++) {
      temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) &
        0x0ffffffff
      E = D
      D = C
      C = rotate_left(B, 30)
      B = A
      A = temp
    }

    for (i = 40; i <= 59; i++) {
      temp = (rotate_left(A, 5) + ((B & C) | (B & D) | (C & D)) + E +
        W[i] + 0x8F1BBCDC) & 0x0ffffffff
      E = D
      D = C
      C = rotate_left(B, 30)
      B = A
      A = temp
    }

    for (i = 60; i <= 79; i++) {
      temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) &
        0x0ffffffff
      E = D
      D = C
      C = rotate_left(B, 30)
      B = A
      A = temp
    }

    H0 = (H0 + A) & 0x0ffffffff
    H1 = (H1 + B) & 0x0ffffffff
    H2 = (H2 + C) & 0x0ffffffff
    H3 = (H3 + D) & 0x0ffffffff
    H4 = (H4 + E) & 0x0ffffffff
  }

  var temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) +
    cvt_hex(H4)
  return temp.toLowerCase()
}

/**
 * Validation fields
 *
 * @param type
 * @param value
 * @return {{errorText: string, error: boolean}}
 */
export const validationFields = (type, value) => {
  let errorText = ''
  let isValid = false

  if (!type || !value) {
    return { errorText: 'This field is mandatory', error: !isValid }
    // throw Error( '"validationFields function - "You didn\'t add required parameters' );
  }

  const phoneREGEX = /^[0-9\+]{9}$/
  const nameREGEX = /^[a-zA-Zа-яА-Я\s]{2,30}$/
  const postalREGEX = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i
  const emailREGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/
  const dummyREGEX = /^.+$/
  const urlREGEX = /^(?:(?:https?|ftp):\/\/)?(?:[\w.-]+)(?:\.[\w.-]+)+(?::\d{2,5})?(?:\/\S*)?$/i

  const formatValue = value.trim().toLowerCase()

  switch (type) {
    case 'user_phone':
    case 'user-phone':
    case 'phone':
      isValid = phoneREGEX.test(formatValue)
      errorText = 'Please enter a valid phone'
      break

    case 'postal':
      isValid = postalREGEX.test(formatValue)
      errorText = 'Please enter a valid zip code'
      break

    case 'date':
      isValid = dummyREGEX.test(formatValue)
      errorText = 'Please enter a valid date'
      break

    case 'company':
    case 'subject':
      isValid = dummyREGEX.test(formatValue)
      errorText = 'Please enter a valid subject'
      break

    case 'business':
    case 'user-message':
      isValid = dummyREGEX.test(formatValue)
      errorText = 'Please enter a valid text'
      break

    case 'name':
    case 'full_name':
    case 'user_name' :
    case 'user-name' :
      errorText = 'Please enter a valid name'
      isValid = nameREGEX.test(formatValue)
      break

    case 'user-email' :
    case 'user_email' :
    case 'email_verify' :
    case 'email' :
      errorText = 'Please enter a valid email address'
      isValid = emailREGEX.test(formatValue)
      break

    case 'password' :
      errorText = 'Please enter a valid password'
      isValid = !!value
      break

    case 'user-url' :
      errorText = 'Please enter a valid url'
      isValid = urlREGEX.test(formatValue)
      break
  }

  return { errorText: errorText, error: !isValid }
}

/**
 * Generate random string
 * @param {number} length
 * @returns {string}
 */
export const makeId = (length = 10) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export const generateKey = (pre) => {
  return `${pre}_${new Date().getTime()}-${makeId()}`
}

/**
 * Create initials from usernames
 * @param userName
 * @return {string}
 */
export const initialsCreation = (userName) => {
  if (!userName) return ''
  const fullName = userName.split(' ')

  if (!fullName.length) return ''
  let firstLetter = (fullName.length) ? fullName.shift() : ''
  let lastLetterInSecondWord = (fullName.length) ? fullName.pop() : ''

  const initials1 = (firstLetter) ? firstLetter.charAt(0) : ''
  const initials2 = (lastLetterInSecondWord) ? lastLetterInSecondWord.charAt(0) : ''

  return (initials1 + initials2).toUpperCase()
}

/**
 * Trim whitespace and remove leading zeros
 * remove non-numeric characters and keep only first 9 digits
 * @param value
 * @returns {*}
 */
export const validPhoneNumber = (value) => {
  let phone = value.trim().replace(/^0+/, '')
  return phone.replace(/\D/g, '').slice(0, 9)
}