import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

const userDetails =
	typeof window !== 'undefined' && localStorage.getItem('pharma_customer');
const isLoggedInUser = userDetails && JSON.parse(userDetails)?.id;

const localStorageCartItems = isLoggedInUser
	? { products: [] }
	: (typeof window !== 'undefined' &&
			JSON.parse(localStorage.getItem('cartItems'))) || { products: [] };

// const localStorageCartItems = (typeof window !== 'undefined' &&
// 	JSON.parse(localStorage.getItem('cartItems'))) || { products: [] };

const initialState = {
	cartItems: localStorageCartItems,
};

export const fetchCartData = createAsyncThunk(
	'fetchCartData',
	async (_, { getState, requestId }) => {
		const { loading, currentRequestId } = getState().cartItems;

		// if (!loading || requestId !== currentRequestId) {
		// 	return;
		// }

		const userDetails =
			typeof window !== 'undefined' && localStorage.getItem('pharma_customer');
		const isLoggedInUser = userDetails && JSON.parse(userDetails)?.id;

		let response;
		if (isLoggedInUser) {
			response = await fetch(
				`${process.env.NEXT_PUBLIC_BACKEND_URL}${
					process.env.NEXT_PUBLIC_CART_API_PATH
				}?user_id=${
					JSON.parse(localStorage.getItem('pharma_customer'))?.id
				}&status=opened`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);
		} else {
			response =
				typeof window !== 'undefined' &&
				JSON.parse(localStorage.getItem('cartItems'));
		}

		const data = await response.json();
		return data; // Return the fetched cart data
	}
);

export const cartItemsSlice = createSlice({
	name: 'cartItems',
	initialState,
	loading: false,
	currentRequestId: undefined,
	reducers: {
		addProductLocally: (state, action) => {
			const { product, uom } = action.payload;

			if (isLoggedInUser === null) {
				state.cartItems?.products.find(
					(itm, index) =>
						itm.id === product.id && state.cartItems.products.splice(index, 1)
				);

				const unit = product.pharma_product_uoms.find(
					(prod) => prod.unit_of_measure === uom
				);
				const amount = unit ? unit.price * (product.quantity || 1) : 0;

				state.cartItems?.products.push({
					// ...action.payload,
					...product,
					// Add required initial quantity property
					// quantity: action.payload.quantity || 1,
					quantity: product.quantity || 1,
					amount: amount,
					selected_amount: amount,
					uom: uom,
				});
			} else {
				state.cartItems?.products.push({
					// ...action.payload,
					...product,
					// Add required initial quantity property
					// quantity: action.payload.quantity || 1,
					quantity: product.quantity || 1,
				});
			}
		},
		increaseQuantityLocally: (state, action) => {
			const existingProductIndex = state.cartItems.products.findIndex(
				(product) => product.id === action.payload.id
			);
			state.cartItems.products[existingProductIndex].quantity =
				(state.cartItems.products[existingProductIndex].quantity || 0) + 1;
		},

		manualUpdateQuantityLocally: (state, action) => {
			const existingProductIndex = state.cartItems.products.findIndex(
				(product) => product.id === action.payload.id
			);
			state.cartItems.products[existingProductIndex].quantity =
				action.payload.quantity;
		},
		decreaseQuantityLocally: (state, action) => {
			const existingProductIndex = state.cartItems.products.findIndex(
				(product) => product.id === action.payload.id
			);
			if (
				isLoggedInUser === null &&
				state.cartItems.products[existingProductIndex].quantity === 1
			) {
				state.cartItems.products.splice(existingProductIndex, 1);
			} else {
				state.cartItems.products[existingProductIndex].quantity--;
			}
		},
		deleteProductLocally: (state, action) => {
			const productIndex = state.cartItems.products.findIndex(
				(product) => product.id === action.payload.id
			);

			if (productIndex !== -1) {
				state.cartItems.products.splice(productIndex, 1);
			} else {
				console.warn(
					`Product with ID ${action.payload.id} not found for deletion.`
				);
			}
		},
		setCartData: (state, action) => {
			state.cartItems.products = action.payload;
		},
	},
	// extraReducers: (builder) => {
	// 	builder.addCase(fetchCartData.fulfilled, (state, action) => {
	// 		// state.cartItems = action.payload?.data[0];

	// 		if (
	// 			action.payload &&
	// 			action.payload.data &&
	// 			action.payload.data.length > 0
	// 		) {
	// 			state.cartItems = action.payload.data[0];
	// 		} else {
	// 			state.cartItems = { products: [] };
	// 		}
	// 	});
	// },
	extraReducers: (builder) => {
		builder
			// .addCase(fetchCartData.pending, (state, action) => {
			// 	if (!state.loading) {
			// 		state.loading = true;
			// 		state.currentRequestId = action.meta.requestId;
			// 	}
			// })
			.addCase(fetchCartData.fulfilled, (state, action) => {
				if (
					action.payload &&
					action.payload.data &&
					action.payload.data.length > 0
				) {
					state.cartItems = action.payload.data[0];
				} else {
					state.cartItems = { products: [] };
				}
				// state.loading = false;
			});
		// .addCase(fetchCartData.rejected, (state) => {
		// 	state.loading = false;
		// 	state.currentRequestId = undefined;
		// });
	},
});

export const {
	addProductLocally,
	increaseQuantityLocally,
	deleteProductLocally,
	decreaseQuantityLocally,
	manualUpdateQuantityLocally,
	setCartData,
} = cartItemsSlice.actions;

export const addNewProductToDB = (product, uom) => async (dispatch) => {
	const userDetails =
		typeof window !== 'undefined' && localStorage.getItem('pharma_customer');
	const isLoggedInUser = userDetails && JSON.parse(userDetails)?.id;

	if (!window.location.href.includes('/checkout') && uom) {
		dispatch(addProductLocally({ product, uom })); // Dispatch the addProduct action first
	}

	if (isLoggedInUser) {
		try {
			const response = await fetch(
				`${process.env.NEXT_PUBLIC_BACKEND_URL}${process.env.NEXT_PUBLIC_CART_API_PATH}/add`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						user_id: JSON.parse(userDetails)?.id,
						product_id: product.id,
						country_code: process.env.NEXT_PUBLIC_COUNTRY_CODE,
						quantity: 1,
						website: 'pharma',
						uom: uom,
					}),
				}
			);

			if (!response.ok) {
				throw new Error('Failed to add product to cart');
			}

			// If successful, dispatch fetchCartData to update cart data
			dispatch(fetchCartData(uom));
		} catch (error) {
			console.error('Error adding product to cart:', error.message);
			// You can dispatch an action here to handle the error if needed
		}
	}
};

export const editExistingProduct = (product, type) => async (dispatch) => {
	const userDetails =
		typeof window !== 'undefined' && localStorage.getItem('pharma_customer');
	const isLoggedInUser = userDetails && JSON.parse(userDetails)?.id;

	if (type === 'decrease') {
		if (product.quantity === 0) {
			dispatch(removeExistingProduct(product));
			return;
		}
		dispatch(decreaseQuantityLocally(product));
	} else if (type === 'increase') {
		dispatch(increaseQuantityLocally(product));
	} else if (type === 'manual') {
		dispatch(manualUpdateQuantityLocally(product));
	}
	if (isLoggedInUser) {
		try {
			// Call the API to add the product to the cart
			const response = await fetch(
				`${process.env.NEXT_PUBLIC_BACKEND_URL}${process.env.NEXT_PUBLIC_CART_API_PATH}/update/${product.cartId}`,
				{
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						product_id: product.id,
						quantity: product.quantity,
					}),
				}
			);

			if (!response.ok) {
				throw new Error('Failed to add product to cart');
			}

			// If successful, dispatch fetchCartData to update cart data
			dispatch(fetchCartData());
		} catch (error) {
			console.error('Error adding product to cart:', error.message);
			// You can dispatch an action here to handle the error if needed
		}
	}
};

export const removeExistingProduct = (product) => async (dispatch) => {
	const userDetails =
		typeof window !== 'undefined' && localStorage.getItem('pharma_customer');
	const isLoggedInUser = userDetails && JSON.parse(userDetails)?.id;

	dispatch(deleteProductLocally(product));
	if (isLoggedInUser) {
		try {
			// Call the API to add the product to the cart
			const response = await fetch(
				`${process.env.NEXT_PUBLIC_BACKEND_URL}${process.env.NEXT_PUBLIC_CART_API_PATH}/${product.cartId}/product/${product.id}`,
				{
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);

			if (!response.ok) {
				throw new Error('Failed to delete product to cart');
			}

			// If successful, dispatch fetchCartData to update cart data
			dispatch(fetchCartData());
		} catch (error) {
			console.error('Error delete product to cart:', error.message);
			// You can dispatch an action here to handle the error if needed
		}
	}
};

export default cartItemsSlice.reducer;
