'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import style from './TopBar.module.css';
// import styled from '@emotion/styled';

// const Wrapper = styled.div`
// 	.topBar {
// 		&__topbar {
// 			text-align: center;
// 			padding: 0.4rem;
// 			background-color: #f3f4f6;
// 			color: #1f2937;
// 			border-bottom: 1px solid #b1bac1;
// 			font-size: 12px;
// 			font-weight: 500;
// 		}

// 		&__topbarLink {
// 			text-decoration: none;
// 			font-weight: 600;
// 			text-align: center;
// 			background-color: #f3f7f7;
// 			font-size: 12px;
// 		}

// 		&__topbar-link {
// 			text-decoration: none;
// 			font-weight: 600;
// 		}
// 	}
// `;

export function TopBar() {
	const pathname = usePathname();
	const excludedRoutes = ['/login', '/registration', '/business'];

	const shouldExcludeTopBar = excludedRoutes.includes(pathname);

	if (shouldExcludeTopBar) {
		return null;
	}

	return (
		<div
			className={`${style.topbar} py-2 d-flex align-items-center justify-content-center`}>
			<div className='m-0'>
				Restock Products For Your Business In Just a Few Clicks Online
			</div>
		</div>
	);
}
