export const getProductsBySearch = async (searchQuery) => {
	const userData = JSON.parse(localStorage.getItem('pharma_customer'));

	const myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');

	const requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	const countryCode = process.env.NEXT_PUBLIC_COUNTRY_CODE;

	if (searchQuery && searchQuery !== '' && searchQuery.length > 2) {
		const res = await fetch(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}${
				process.env.NEXT_PUBLIC_PHARMA_ALL_PRODUCTS
			}?search=${searchQuery}&country=${countryCode}&category=A03&location=${
				userData ? userData?.location : ''
			}`,
			requestOptions
		);
		const jsonData = await res.json();

		if (jsonData.data.length > 0) {
			const filteredProducts = jsonData?.data?.filter((prod) => {
				return prod.total_inventory > 0 && prod.pharma_product_uoms.length > 0;
			});

			return filteredProducts;
		}

		return [];
	}
};
