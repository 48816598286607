'use client';

import { useState, useEffect } from 'react';
import style from './Category.module.css';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { PreLoader } from '../preloader/PreLoader';

const CategoriesMenu = ({ navbarItems = [], categoryTitle = '' }) => {
	const isActiveLink = usePathname();
	const router = useRouter();

	const [screenWidth, setScreenWidth] = useState(
		typeof window !== 'undefined'
			? window.innerWidth
				? window.innerWidth > 450
					? window.innerWidth - 80
					: window.innerWidth
				: null
			: null
	);

	const [isClient, setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleDropdownChange = (e) => {
		if (e.target.value !== '') {
			router.push(`/category/${e.target.value}`);
		}
	};

	return (
		<nav style={{ fontSize: '12px', width: '100%' }}>
			{isClient ? (
				<ul
					className={style.nav__links}
					style={screenWidth > 450 ? null : { justifyContent: 'center' }}>
					{screenWidth > 450 && navbarItems && !categoryTitle && (
						<li className={style.navListItem}>
							<div className={`${style.group} ${style.active}`}>
								<Image
									src={`/images/all.svg`}
									alt='Home Icon'
									width={16}
									height={16}
								/>
								All
							</div>
						</li>
					)}
					{screenWidth > 450 ? (
						navbarItems &&
						navbarItems.map((item, index) =>
							item.itemName === 'First Aid' ||
							item.itemName === 'Contraception' ? null : (
								<li className={style.navListItem} key={index}>
									<Link
										href={`/category/${item.icon}`}
										replace
										// onClick={() => router.reload()}
									>
										<div
											className={`${style.group} ${
												item.icon === categoryTitle ? style.active : ''
											}`}>
											<Image
												src={`/images/${item.icon}.svg`}
												alt={item.icon}
												width={16}
												height={16}
											/>
											{item.itemName}
										</div>
									</Link>
								</li>
							)
						)
					) : (
						<li
							className={style.navListItem}
							key={1}
							style={{ display: 'contents' }}>
							<div
								style={
									isActiveLink === '/'
										? categoryTitle === ''
											? { padding: '1.3rem' }
											: null
										: null
								}>
								{navbarItems.length === 0 ? (
									<PreLoader />
								) : (
									<select
										name='business_type'
										className={style.selectInput}
										placeholder='Products By Category'
										autoComplete='off'
										value={categoryTitle}
										required
										onChange={handleDropdownChange}>
										<option value=''>Products By Category</option>
										{navbarItems &&
											navbarItems.map((item, index) =>
												item.itemName === 'First Aid' ||
												item.itemName === 'Contraception' ? null : (
													<option value={item.icon} key={index}>
														{item.itemName}
													</option>
												)
											)}
									</select>
								)}
							</div>
						</li>
					)}
				</ul>
			) : null}
		</nav>
	);
};

export default CategoriesMenu;
